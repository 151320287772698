import '../css/EntraceReq.css';
import Dropdown from "./comps/DropDown";
import React, {useEffect, useState} from "react";
import Modal from "./comps/Modal";
import ConfirmBox from "./comps/ConfirmBox";
import departmanage from "../services/departmanage";
import persongroupmanage from "../services/persongroupmanage";
import DatePickersValid from "./comps/DatePickersValid";
import logs from "../services/logs";
import helpers from "../services/helpers";
import datamanage from "../services/datamanage";

function EntranceReq() {
    const [groupsData, setGroupsData] = useState([])
    const [isMessage, setMessage] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [chx, setChx] = useState(false);
    const [isDone, setDone] = useState(false);
    const [perGroup,setPerGroupsData] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (logs.isLog()) console.log('Form Submitted');
        const addReq = async () => {
            try {
                let timeStart =0
                let timeEnd =0
                if(chx){
                    timeStart = helpers.convertToUnixMilliseconds(document.getElementById('carStartT').value)
                    timeEnd = helpers.convertToUnixMilliseconds(document.getElementById('carendT').value)
                }
                let dataBuild = {
                    personalIDNum: document.getElementById('perosnalIDNum').value,
                    carID: document.getElementById('carID').value,
                    plateType: document.getElementById('isMilitary').value,
                    ownerFirstName: document.getElementById('ownerFirstName').value,
                    ownerLastName: document.getElementById('ownerLastName').value,
                    departmentID: document.getElementById('groupId').value,
                    ownerPhone: document.getElementById('ownerPhone').value,
                    carManufacturer: document.getElementById('carManufacturer').value,
                    carColor: document.getElementById('carColor').value,
                    reason: document.getElementById('reason').value,
                    personGroup: document.getElementById('personalId').value,
                    requestType: chx? '3': '1',
                    startDate: timeStart ? timeStart : 0,
                    endDate: timeEnd ? timeEnd : 0,
                }
                const addedReq = await addRequest(dataBuild)
                if (addedReq) {
                    setDone(true)
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        }
        addReq()
        // Submit the form data
    };

    async function addRequest(dataInfo){
        try {
            let data = {'authentication': {"key": helpers.getToken()}, 'data': dataInfo, 'type': {'act': 'addRequest','returnInput': '1'}}
            return await datamanage.sendData(data, 'sendback')
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }

    useEffect(() => {
        getGroupsForUsers();
        getPersonalGroups();
    }, []);


    const closeModal = () => {
        setIsModalOpen(false)
    }

    const getGroupsForUsers = async () => {
        try {
            const groupData = await departmanage.getAllDeptsList();
            if (groupData) {
                setGroupsData(groupData)
            } else setGroupsData([]);
        } catch
            (error) {
            console.error('Error fetching cars:', error);
        }
    }
    const getPersonalGroups = async () => {
        try {
            const pGroupData = await persongroupmanage.getAllPersonalGroups();
            if (pGroupData) {
                setPerGroupsData(pGroupData)
            } else setPerGroupsData([]);
        } catch
            (error) {
            console.error('Error fetching cars:', error);
        }
    }

    return (
        <>
            {isDone ? (
                <div>נשלח בהצלחה</div>
            ) : (
                <>
                    <Modal isOpen={isModalOpen} onClose={closeModal}>
                        <ConfirmBox message={isMessage} onClose={closeModal} onConfirm={''}/>
                    </Modal>
                    <form style={{padding:'1rem',margin:'auto',backgroundColor:'#ccc'}} dir="rtl" onSubmit={handleSubmit} className="form-container enter-req">
                        <input
                            type="text"
                            id={'carID'}
                            placeholder="מספר רכב"
                        />
                        <input
                            type="text"
                            id={'perosnalIDNum'}
                            placeholder="מא או תז"
                        />
                        <input
                            type="text"
                            id={'ownerFirstName'}
                            placeholder="שם פרטי"
                        />
                        <input
                            type="text"
                            id={'ownerLastName'}
                            placeholder="שם משפחה"
                        />
                        <Dropdown  title={'סוג שירות:'} opts={perGroup} type={3} idName='personalId'/>
                        <input
                            type="phone"
                            id={'ownerPhone'}
                            placeholder="סלולר"
                        />
                        <input
                            type="text"
                            id={'carManufacturer'}
                            placeholder="סוג רכב"
                        />
                        <input
                            type="text"
                            id={'carColor'}
                            placeholder="צבע"
                        />
                        <Dropdown title={'סוג לוחית רישוי'}
                                  opts={[{text: 'אזרחית', value: 1}, {text: 'צבאית', value: 2},{text: 'משטרתית', value: 3}]}
                                  idName='isMilitary'/>                        <Dropdown title={'שיוך ל'} opts={groupsData} type={1} idName='groupId' pickedVal={4}/>
                        <label>סוג כניסה:</label>
                        <DatePickersValid givenID='editCarChx' onCheckboxChange={setChx} isCheck={chx} sTid='carStartT'
                                          eTid='carendT'/>
                        <textarea
                            id={'reason'}
                            placeholder="סיבה"
                        />
                        <button type="submit">שלח בקשה</button>
                    </form>
                </>
            )}
        </>
    );
}

export default EntranceReq;
