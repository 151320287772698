import React, {useState} from 'react';

function Dropdown(props) {
    const [selectedValue, setSelectedValue] = useState(props.pickedVal ? props.pickedVal : -1);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    function makeOpts(optionsArray, type = null) {
        if(optionsArray){
            optionsArray = Array.isArray(optionsArray) ? optionsArray : [optionsArray]
            if (type == null) {
                return optionsArray.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.text}
                    </option>));
            } else if (type == 1) {
                return optionsArray.map((option, index) => (
                    <option key={index} value={option.ID}>
                        {option.departmentsName}
                    </option>));
            } else if (type == 3) {
                return optionsArray.map((option, index) => (
                    <option key={index} value={option.ID}>
                        {option.personGroupName}
                    </option>));
            } else {
                return optionsArray.map((option, index) => (
                    <option key={index} value={option.ID}>
                        {option.groupName}
                    </option>));
            }
        }
    }

    return (
        <div className={"select-wrapper"}>
            <select id={props.idName} defaultValue={selectedValue} onChange={handleChange}>
                <option key={'none'} value={-1} disabled>{props.title}</option>
                {makeOpts(props.opts, props.type)}
            </select>
        </div>
    );
}

export default Dropdown;
