import React from 'react';
import { Link, useLocation } from "react-router-dom";
import '../css/error404.css'

function LocationComponent({ children }) {
    const location = useLocation();
    const isOtherPath = location.pathname !== '/' && location.pathname !== '/EntReqOut';
    // Only render children if it's a different path
    return isOtherPath ? children : null;
}

const Error404 = (props) => {
    return (
        <LocationComponent>
            <div className="not-found-container">
                <div className="not-found-text">
                    <h1>404</h1>
                    <h2>נראה שהלכתם לאיבוד</h2>
                    <p>להמשך גלישה באתר, חזרו אחורה</p>
                </div>
                <div className="not-found-animation">
                    {/* Add some funny image or animation here */}
                    <img src="path_to_your_funny_image_or_gif" alt="" />
                </div>
                <button><Link to={'/'}>חזור לעמוד ראשי</Link></button>
            </div>
        </LocationComponent>
    );
};

export default Error404;
