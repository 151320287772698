import logs from "./logs";
import Cookies from 'js-cookie';

export default {
    getCookie,
    getCookieVal,
    setCookie,
    removeCookie,
    removeAllCookies
}

function removeCookie(key){
    Cookies.response(key)
}

function removeAllCookies(){
    const allCookies = Cookies.get();
    for (let key in allCookies) {
        Cookies.remove(key);
    }
}
function setCookie(key,value,time){
    Cookies.set(key,value,{expires: parseInt(time)/24 , sameSite: 'Lax'})
}

function getCookieVal(key){
    const cookieValue = Cookies.get(key);
    return cookieValue ? cookieValue : false;
}

function getCookie(key){
    const cookieValue = Cookies.get(key);
    if (cookieValue) {
        if(logs.isLog()) console.log(`Cookie found: ${key} = ${cookieValue}`);
        return true;
    } else {
        if(logs.isLog()) console.log(`Cookie not found: ${key}`);
        return false;
    }
}