import React from 'react';


function timeFix(timeStamp) {
    if (timeStamp !== 0) {
        return new Date(parseInt(timeStamp)).toLocaleString('he-IL', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'Asia/Jerusalem'
        })
    } else {
        return '-'
    }
}

function SSEComponent({data, pickCar}) { // Destructure `data` directly from props
    function getCar(func, id) {
        func(id)
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth"
        });
    }

    const renderCards = (data) => { // No need to destructure in the parameter here
        return data.map((item, index) => (
            <div key={index} className="card" onClick={() => getCar(pickCar, item.carID)}>
                <div className="container">
                    <div dir={"rtl"}>זמן ביצוע: {item.time}</div>
                    <div>מספר רכב: {item.carID}</div>
                    <div>סוג: {item.typeName}</div>
                    <div>מבצע הפעולה: {item.approverFirstName} {item.approverLastName}</div>
                </div>
            </div>
        ));
    };

    return (
        <div>
            <p dir={'ltr'}> <span>{new Date().toLocaleString('he-IL', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                timeZone: 'Asia/Jerusalem'
            })}</span> :שעה </p>
            {data && renderCards(data)} {/* Call `renderCards` directly with `data` */}
        </div>
    );
}

export default SSEComponent;