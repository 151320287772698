import helpers from "./helpers";
import datamanage from "./datamanage";

async function addSubscription() {
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': {}, 'type': {'act': 'subscribePushNotifications'}}
        let fixedData = await subscribeToPushNotifications(data)
        if(fixedData){
            return await datamanage.sendData(fixedData)
        }
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}


async function subscribeToPushNotifications(data) {
    try {
        // First, register the service worker
        const swRegistration = await navigator.serviceWorker.register('serviceWorker.js');
        console.log('Service worker successfully registered.', swRegistration);

        // Wait for the service worker to be ready
        const registration = await navigator.serviceWorker.ready;
        console.log('Service Worker is ready.');

        // Subscribe to push notifications
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array('BPOAwT18QHitFZLFq8wbO9pb7IKrda2xTbeQaUwu1WMtvteIqYzcCJmub-stxnniREQlgiQ1fC5KCUR3ItO9BR4') // Replace 'somekey' with your VAPID public key
        });
        console.log('User is subscribed:', subscription);

        // Convert keys from ArrayBuffer to Base64 string
        const key_p256dh = arrayBufferToBase64(subscription.getKey('p256dh'));
        const key_auth = arrayBufferToBase64(subscription.getKey('auth'));

        // Prepare subscription details
        let subscriptionDetails = {
            endpoint: subscription.endpoint,
            keysP256dh: key_p256dh,
            keysAuth: key_auth
        };

        // Assuming 'data' is the object you intend to send or use
        data.data = subscriptionDetails; // Add subscription details to your data object

        // Return the modified 'data' object with subscription details added
        return data;
    } catch (error) {
        console.error('Subscription failed:', error);
        // Depending on how you want to handle errors, you might want to return null or an error object
        return null; // or throw error;
    }
}

// Utility function to convert ArrayBuffer to Base64 string
function arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}


// Helper function for converting your VAPID public key
function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}


export default {
    addSubscription
}