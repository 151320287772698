import DataList from "./comps/DataList";
import logs from "../services/logs";
import React, {useEffect, useState} from 'react';
import Modal from './comps/Modal.js'
import ConfirmBox from "./comps/ConfirmBox";
import helpers from "../services/helpers";
import departmanage from "../services/departmanage";


function Departments() {
    const [isMessage, setMessage] = useState('')
    const actionBtns = [{'name': 'מחיקה', 'action': delDept}, {
        'name': 'עריכה\\צפייה',
        'action': editDept
    }]
    const [showForm, setShowForm] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [approveFunc, setApproveFunc] = useState(null)
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pickedId, setPickedId] = useState(null)
    const [sortConfig, setSortConfig] = useState({key: null, direction: 'ascending'});
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);

    const closeModalAdd =() =>{
        setIsModalAddOpen(false)
    }
    //commands
    function delDept(item) {
        if (logs.isLog()) console.log('מחיקה', item)
        setMessage(
            <>
                ?האם בטוח שברצונך למחוק את <span style={{color: 'red'}}>{item['שם מחלקה']}</span>
            </>)
        setIsModalOpen(true)
        setPickedId(item['סידורי'])
        setApproveFunc('del')
    }

    const confirmDel = () => {
        const delDept = async () => {
            try {
                let dataBuild = {
                    ID: `${pickedId}`,
                    status: '0',
                }
                const blockedDept = await departmanage.delDept(dataBuild)
                setIsModalOpen(false)
                if (blockedDept) {
                    fetchDepts()
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        }
        delDept()
    }

    function editDept(item) {
        if (logs.isLog()) console.log('עריכה', item)
        setIsModalOpen(true)
        setPickedId(item['סידורי'])
        setMessage(
            <div dir={'rtl'}>
                <input type={'text'} id={'editName'} defaultValue={item['שם מחלקה']}/>
            </div>)
        setApproveFunc('edit')
    }

    function confirmEdit() {
        const saveDept = async () => {
            try {
                let dataBuild = {
                    ID: `${pickedId}`,
                    name: document.getElementById('editName').value,
                }
                const savedDept = await departmanage.editDept(dataBuild, failBackFunc)
                if (savedDept) {
                    fetchDepts()
                    setIsModalOpen(false)
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        }
        saveDept()
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }
    const handleAddUserClick = () => {
        setShowForm(true);
        setIsModalAddOpen(true)
    };
    const handleCloseAddUserClick = () => {
        setShowForm(false)
    }
    const addNewDept = () => {
        let name = document.querySelector('.dept-name-inp').value;
        const addDept = async () => {
            try {
                const addedDept = await departmanage.addDept(name, failBackFunc)
                if (addedDept) {
                    fetchDepts()
                    setShowForm(false)
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        }
        addDept()
    };

    function failBackFunc(data) {
        switch (data.problem) {
            case 'dupl':
                // eslint-disable-next-line default-case
                switch (data.area) {
                    case 'addDepartment':
                        setApproveFunc('reverse')
                        setPickedId(data.id)
                        setMessage(<div dir={'rtl'}> שגיאה: שם זה כבר היה קיים בעבר במערכת , יש לשנות שם או לשחזר את
                            הישן .
                            האם ברצונך לשחזר ?</div>)
                        setIsModalOpen(true)
                        break;
                    case 'editDepartment':
                        setApproveFunc('reverse')
                        setMessage(<div dir={'rtl'}> שגיאה: שם זה כבר היה קיים במערכת , יש לשנות שם או לשחזר את
                            הישן </div>)
                        setIsModalOpen(true)
                        break;
                }
                break;
        }
    }

    function reverseDept() {
        const revDept = async () => {
            try {
                let dataBuild = {
                    ID: pickedId,
                    status: '1',
                }
                const newDept = await departmanage.delDept(dataBuild)
                setIsModalOpen(false)
                if (newDept) {
                    fetchDepts()
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        }
        revDept()
    }

    const chooseFunc = () => {
        switch (approveFunc) {
            case 'del':
                return confirmDel;
            case 'edit':
                return confirmEdit;
            case 'reverse':
                return reverseDept;
            default:
                return ''
        }
    }

    // Filter logic
    const filteredData = data.filter(user => {
        // Calculate indices for slicing the data array
        // Convert all values of the car object into a single string
        const userValueString = Object.values(user).join(' ').toLowerCase();
        // Check if this string includes the search query
        return userValueString.includes(searchQuery.toLowerCase());
    });

    const handleSort = (key, isNumeric = false) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        const sortedData = data.slice().sort((carA, carB) => {
            // For numeric values
            if (isNumeric) {
                return direction === 'ascending' ? carA[key] - carB[key] : carB[key] - carA[key];
            }
            // For string values
            return direction === 'ascending'
                ? (carA[key] || '').toString().localeCompare((carB[key] || '').toString())
                : (carB[key] || '').toString().localeCompare((carA[key] || '').toString());
        });
        setData(sortedData);
        setSortConfig({key, direction});
    };

    useEffect(() => {
        fetchDepts();
    }, []);

    const fetchDepts = async () => {
        try {
            const deptsData = await departmanage.getAllDeptsList();
            // Check if deptsData is truthy and ensure it's an array
            if (deptsData) {
                let deptsArray;
                if (Array.isArray(deptsData)) {
                    deptsArray = deptsData;
                } else if (typeof deptsData === 'object' && deptsData !== null) {
                    deptsArray = [deptsData];
                } else {
                    deptsArray = []; // Set a default value to avoid breaking the application
                }
                const transformedDepts = helpers.transformKeys(deptsArray);
                setData(transformedDepts);
            }
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };
    return (
        <div>
            <button onClick={handleAddUserClick}>הוסף מחלקה</button>
            {showForm && (
                <Modal isOpen={isModalAddOpen} onClose={closeModalAdd}>
                    <div dir={'rtl'}>
                        <h2>הוספת מחלקה חדש</h2>
                        <div>
                            {/* Replace with your form fields */}
                            <input className={'dept-name-inp'} type="text" placeholder="שם קבוצה"/>
                            <button onClick={addNewDept}>שמור</button>
                            <button onClick={handleCloseAddUserClick}>ביטול</button>
                        </div>
                    </div>
                </Modal>
            )}
            <input
                type="text"
                placeholder="חפש..." // Placeholder in Hebrew
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <ConfirmBox message={isMessage} onClose={closeModal} onConfirm={chooseFunc()}/>
            </Modal>
            <div className={"car-list-wrapper car-list"}>
                <DataList title={'רשימת קבוצות'} data={filteredData} sortAction={handleSort} actions={actionBtns}/>
            </div>
        </div>
    );
}


export default Departments;