import helpers from "../services/helpers";
import datamanage from "./datamanage";


async function getAllAlerts(){
    let isValid = _isAlertListSaved()
    if(!isValid){
        try {
            let data = {'authentication':{"key": helpers.getToken()},'data':{},'type':{'act':'announcmentList'}}
            let response =  await datamanage.sendData(data, 'sendback')
            if(response){
                helpers.saveSession('alert-list',response)
                return response
            }else return null
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }else {
        return  isValid
    }
}

function _isAlertListSaved(){
    let isAlertList = helpers.isSessionValid('alert-list')
    if(isAlertList) return isAlertList
    else return false
}


async function delAlert(dataInfo){
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': dataInfo, 'type': {'act': 'changeAnnouncmentStatus'}}
        return await datamanage.sendData(data, 'sendback')
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}

async function editAlert(dataInfo){
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': dataInfo, 'type': {'act': 'editAnnouncment'}}
        return await datamanage.sendData(data, 'sendback')
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}

async function addAlert(dataInfo){
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': dataInfo, 'type': {'act': 'addAnnouncment'}}
        return await datamanage.sendData(data, 'sendback')
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}

export default {
    getAllAlerts,
    delAlert,
    editAlert,
    addAlert
}