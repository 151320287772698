
import '../../css/cardOpen.css'

function Card({ title, content,content2='', isEdit ,openCard='',cardClass='',isClose}) {
    function isEditBtn(){
        if(isEdit) return <button onClick={()=>isEdit.action(isEdit.value)}>{isEdit.text}</button>
        else return  ''
    }
    function isCloseBtn(){
        if(isClose) return <button onClick={()=>isClose.action(isClose.value)}>{isClose.text}</button>
        else return  ''
    }
    return (
        <div className={`${cardClass} card`} onClick={()=>{openCard && openCard()}}>
            <h3>{title}</h3>
            <p>{content}</p>
            {content2 && <p>{content2}</p>}
            {isCloseBtn() && <div>
                {isCloseBtn()}
                {isEditBtn()}
            </div>}
        </div>
    );
}

export default Card;