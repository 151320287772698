import React from 'react';
import * as XLSX from 'xlsx';
import helpers from "../../services/helpers";
import '../../css/datalist.css'
import excelImg from  '../../images/excel.svg'
function DataList({ data, actions, title, sortAction }) {
    // Check if data is available and not empty
    if (!data || data.length === 0) {
        return <p>לא נמצאו פריטים</p>;
    }

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb, `${title + ' ' + helpers.getCurrentDate()}.xlsx`);
    };

    // Extract keys from the first object to use as table headers
    const headers = data.length > 0 ? Object.keys(data[0]).filter(key => key !== 'סידורי') : [];
    const handleHeaderClick = (header) => {
        // Pass the header to the sortAction function
        sortAction(header);
    };
    const makeBtn = (type=null,idx,row,name,func) =>{
        if(type){
            return (
                <img className={'act-btn'} key={idx} src={name} onClick={() => handleAction(row, func)}/>
            )
        }else{
            return (
                <button key={idx} onClick={() => handleAction(row, func)}>
                    {name}
                </button>
            )
        }
    }
    return (
        <div className={'table-list-wrapper'}>
            {/* Table for the header */}
            <table className={'table-header'} dir={'rtl'}>
                <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index} onClick={() => handleHeaderClick(header)}>
                            {header}
                        </th>
                    ))}
                    <th>פעולות</th> {/* Additional header for the action column */}
                </tr>
                </thead>
            </table>

            {/* Table for the body */}
            <div className={'table-body-wrapper'}>
                <table className={'table-body'} dir={'rtl'}>
                    <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {headers.map((header, colIndex) => (
                                <td key={colIndex}>{row[header]}</td>
                            ))}
                            <td>
                                {actions.map((actionBtn, idx) => (
                                    makeBtn(actionBtn.type,idx,row,actionBtn['name'],actionBtn.action)
                                ))}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <img className={'exl-btn'} src={excelImg} onClick={exportToExcel}/>
        </div>
    );
}

function handleAction(item, func) {
    func(item);
}

export default DataList;
