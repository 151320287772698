import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from "react-datepicker";
import he from 'date-fns/locale/he';


registerLocale('he', he);

function ConditionalDateTimePicker(props) {
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        if(props.eTVal){
            setEndDate(new Date(parseInt(props.eTVal)))
            setStartDate(new Date(parseInt(props.sTVal)))
        }
    }, [props.eTVal, props.sTVal]);

    return (
        <div>
                    <label>התחלה:</label>
                    <DatePicker
                        id={props.sTid}
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="שעה"
                        locale='he'
                    />
                    <label>סיום:</label>
                    <DatePicker
                        id={props.eTid}
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="שעה"
                        locale='he'
                    />
        </div>
    );
}

export default ConditionalDateTimePicker;
