import React, {useState, useEffect} from 'react';
import Card from "./comps/Card";
import alertsmanage from "../services/alertsmanage";
import Modal from "./comps/Modal";
import ConfirmBox from "./comps/ConfirmBox";
import logs from "../services/logs";
import helpers from "../services/helpers";
import Loading from "./comps/Loading";
import '../css/alertpage.css'

const SendAlert = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [approveFunc, setApproveFunc] = useState(null);
    const [isMessage, setMessage] = useState('');
    const [pickedId, setPickedId] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state

    const closeModal = () => {
        setIsModalOpen(false);
    }
    const fetchAlertsAndUsers = async () => {
        const fetchedAlerts = await alertsmanage.getAllAlerts(); // Fetch alerts
        if(fetchedAlerts) setAlerts(fetchedAlerts);
        else setAlerts([])
        setIsLoading(false);
    };

    useEffect(() => {
        fetchAlertsAndUsers();
    }, []);

    function makeNewAlert() {
        setIsModalOpen(true)
        let content = document.querySelector('.alert-text').value
        setApproveFunc('new')
        setMessage(
            <>
                האם להוסיף את"<span style={{color: 'red'}}>{content}</span>" לרשימת ההתראות?
            </>
        )
    }

    const approveAlert = () => {
        let content = document.querySelector('.alert-text').value
        if (logs.isLog()) console.log('Form Submitted');
        const addAlert = async () => {
            try {
                let dataBuild = {
                    message: content,
                    time: new Date().getTime()
                }
                const addedAlert = await alertsmanage.addAlert(dataBuild)
                setIsLoading(true);
                if (addedAlert) {
                    fetchAlertsAndUsers()
                    setIsModalOpen(false)
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        }
        addAlert()
        // Submit the form data
    };

    function editAlert(item) {
        setIsModalOpen(true)
        setApproveFunc('edit')
        setPickedId(item.ID)
        setMessage(
            <textarea data-idcard={item.id} className={"edit-alert-text"} defaultValue={item.message}>
            </textarea>
        )
    }

    const saveAlert = () => {
        let content = document.querySelector('.edit-alert-text').value
        const saveAlert = async () => {
            try {
                let dataBuild = {
                    message: content,
                    ID: pickedId,
                    time: new Date().getTime()
                }
                const savedAlert = await alertsmanage.editAlert(dataBuild)
                setIsLoading(true);
                setIsModalOpen(false)
                if (savedAlert) {
                    fetchAlertsAndUsers()
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        }
        saveAlert()
    }

    function delAlet(item) {
        if (logs.isLog()) console.log('מחיקה', item.content)
        setMessage(
            <>
                ?האם בטוח שברצונך למחוק את <span style={{color: 'red'}}>{item.content}</span>
            </>)
        setIsModalOpen(true)
        setPickedId(item.ID)
        setApproveFunc('del')
    }

    const confirmDel = () => {
        const closeAlert = async () => {
            try {
                let dataBuild = {
                    ID: pickedId,
                    status: '0'
                }
                const changeAlertStat = await alertsmanage.delAlert(dataBuild)
                setIsLoading(true);
                setIsModalOpen(false)
                if (changeAlertStat) {
                    fetchAlertsAndUsers()
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        }
        closeAlert()
    }

    const chooseFunc = () => {
        switch (approveFunc) {
            case 'del':
                return confirmDel;
            case 'edit':
                return saveAlert;
            case 'new':
                return approveAlert
            default:
                return ''
        }
    }

    function makeAlertsVisual(data){
        if(Array.isArray(data)){
            if(data.length < 1 )return <div>לא נמצאו התראות</div>
            else {
                return (
                    data.map(alert => (
                            <Card
                                key={alert.ID}
                                title={helpers.getCurrentDate(alert.time)}
                                content={`${alert.message} מאת: ${alert.privateName ?  alert.privateName + ' ' + alert.lastName : 'לא ידוע'} `}
                                isEdit={{'text': 'עריכה', 'action': editAlert, 'value': alert}}
                                isClose={{'text': 'מחיקה', 'action': delAlet, 'value': alert}}
                            />
                        )
                    ))
            }
        }else if(data){
            return (
                <Card
                    key={data.ID}
                    title={helpers.getCurrentDate(data.time)}
                    content={`${data.message} מאת: ${data.privateName ?  data.privateName + ' ' + data.lastName : 'לא ידוע'} `}
                    isEdit={{'text': 'עריכה', 'action': editAlert, 'value': data}}
                    isClose={{'text': 'מחיקה', 'action': delAlet, 'value': data}}
                />
            )
        }else return <div>לא נמצאו התראות</div>
    }

    return (
        <div className={"alert-wrapper"}>
            <div className={"alert-txt-btn"}>
            <textarea className={"alert-text"}></textarea>
                <button onClick={makeNewAlert}>שלח</button>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <ConfirmBox message={isMessage} onClose={closeModal} onConfirm={chooseFunc()}/>
            </Modal>
            <section className={"alert-history"}>
                {isLoading ? (
                    <Loading />
                ) : alerts  ?( makeAlertsVisual(alerts)) : <div>לא נמצאו התראות</div>
                }
            </section>
        </div>
    );
};

export default SendAlert;