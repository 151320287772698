import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/serviceWorker.js').then(registration => {
        registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                        // New update available
                        // Prompt the user to refresh
                    }
                }
            };
        };
    }).catch(error => {
        console.log('Service Worker registration failed:', error);
    })
}
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);


