import React, {useEffect, useState} from 'react';
import QRCode from 'qrcode.react';
import logo2 from "../../images/logo3.png";
import { useLocation } from 'react-router-dom';
import helpers from "../../services/helpers";
import datamanage from "../../services/datamanage";
import logs from "../../services/logs";
import Loading from "./Loading";

const MyQRCodeComponent = () => {
    const [tokenUrl,setTokenUrl] = useState(null)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id'); // Get 'id' from the query string

    useEffect(() => {
        async function getCarToken(){
            if (id) {
                try {
                    let data = {
                        'authentication': {"key": helpers.getToken()},
                        'data': {'ID': id},
                        'type': {'act': 'getCarToken'}
                    };
                    const savedToken = await datamanage.sendData(data, 'sendback');
                    if (savedToken) setTokenUrl(savedToken);
                } catch (error) {
                    console.error('Failed to fetch data:', error);
                }
            }
        }

        if(logs.isLog()) console.log("ID from URL:", id);
        getCarToken();
    }, [id]);

    return (
        <div>
            {tokenUrl ? (
                <QRCode
                    value={`https://www.oto.lega6.com/QrRead/?searchLabel=${tokenUrl}`}
                    size={512}
                    imageSettings={{
                        src: logo2,
                        height: 120,
                        width: 120,
                    }}
                    includeMargin={true}
                />
            ) : (
                <Loading/> // or some other placeholder
            )}
        </div>
    );
};

export default MyQRCodeComponent;
