import React, {useEffect, useState} from 'react';
import DataList from "./comps/DataList";
import logs from "../services/logs";
import usersmanage from "../services/usersmanage";
import Modal from "./comps/Modal";
import ConfirmBox from "./comps/ConfirmBox";
import helpers from "../services/helpers";
import Loading from "./comps/Loading";
import Pagination from "./comps/Pagination";
import groupmanage from "../services/groupmanage";
import Dropdown from "./comps/DropDown";
import cookiesmanage from "../services/cookiesmanage";

const Users = () => {
    const actionBtns = [{'name': 'עריכה\\צפייה', 'action': editUser}, {
        'name': 'איפוס סיסמא',
        'action': passReset
    }, {'name': 'חסימת משתמש', 'action': blockUser}]
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // State to track loading status
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({key: null, direction: 'ascending'});
    const [showForm, setShowForm] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [approveFunc, setApproveFunc] = useState(null)
    const [pickedId, setPickedId] = useState(null)
    const [isMessage, setMessage] = useState('')
    let [errorMsg, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);
    const [newPass,setNewPass] = useState('')
    const [groupsData, setGroupsData] = useState([])
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);

    const closeModalAdd =() =>{
        setIsModalAddOpen(false)
    }
    //commands
    const closeModal = () => {
        setIsModalOpen(false)
    }
    const handleAddUserClick = () => {
        setShowForm(true);
        setIsModalAddOpen(true)
    };
    const handleCloseAddUserClick = () => {
        setShowForm(false)
    }
    const isValidPassword = (password) => {
        const isPass = /[a-zA-Z]/.test(password)
        return isPass && password.length > 5;
    };
    const isValidPhoneNumber = (phone) => {
        const isNumeric = /^\d+$/.test(phone);
        return isNumeric && phone.startsWith('05') && phone.length === 10;
    };

    function editUser(item) {
        if (logs.isLog()) console.log('עריכת משתמש', item)
        setIsModalOpen(true)
        setPickedId(item['סידורי'])
        setApproveFunc('edit')
        setMessage(<>{fillEditForm(item)}</>)
    }

    function fillEditForm(data) {
        return <div dir={'rtl'}>
            <input type="number" id={'editUserID'} placeholder={'תז או מא'} defaultValue={data['מספר זיהוי']}/>
            <input type="text" id={'editfName'} placeholder="שם פרטי" defaultValue={data['שם פרטי']}/>
            <input type="text" id={'editlName'} placeholder="שם משפחה" defaultValue={data['שם משפחה']}/>
            <input type="number" id={'editPhone'} placeholder="טלפון" defaultValue={data['סלולר']}/>
            <input type="password" defaultValue={'*******'} disabled/>
            <Dropdown opts={groupsData} idName='editGroupId' type={2} />
        </div>
    }

    function saveUser() {
        const saveUser = async () => {
            try {
                let dataBuild = {
                    ID: `${pickedId}`,
                    privateName: document.getElementById('editfName').value,
                    personalID: document.getElementById('editUserID').value,
                    lastName: document.getElementById('editlName').value,
                    phone: document.getElementById('editPhone').value,
                    groupID: document.getElementById('editGroupId').value,
                }
                const savedUser = await usersmanage.editUser(dataBuild)
                if (savedUser) {
                    setIsLoading(true);
                    setIsModalOpen(false)
                    loadUsers()
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        }
        saveUser()
    }

    function passReset(item) {
        setPickedId(item)
        setIsModalOpen(true)
        let newPass = usersmanage.generatePass()
        setMessage(<>
            <div dir={"rtl"}>סיסמא חדשה שנבחרה ל <span
                style={{color: 'red'}}>{item['שם פרטי']} {item['שם משפחה']}</span>:
            </div>
            {newPass}
        </>)
        if (logs.isLog()) console.log('איפוס סיסמאת משתמש', item)
        setApproveFunc('pass')
        setNewPass(newPass)
    }

    function blockUser(item) {
        setIsModalOpen(true)
        setMessage(
            <>
                ?האם בטוח שברצונך לחסום את <span style={{color: 'red'}}>{item['שם פרטי']} {item['שם משפחה']}</span>
            </>
        )
        setApproveFunc('del')
        setPickedId(item['סידורי'])
        if (logs.isLog()) console.log('חסימת משתמש', item)
    }

    function resetPassUser() {
        let dataItem = pickedId
        const saveUser = async () => {
            try {
                let dataBuild = {
                    ID: `${dataItem['סידורי']}`,
                    password: newPass,
                }
                const savedUser = await usersmanage.editPass(dataBuild)
                setIsLoading(true);
                setIsModalOpen(false)
                if (savedUser) {
                    loadUsers()
                    setNewPass('')
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        }
        saveUser()
    }

    const confirmDel = () => {
        const saveUser = async () => {
            try {
                let dataBuild = {
                    ID: `${pickedId}`,
                    status: '2',
                }
                const blockedUser = await usersmanage.blockUser(dataBuild)
                setIsLoading(true);
                setIsModalOpen(false)
                if (blockedUser) {
                    loadUsers()
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        }
        saveUser()
    }

    const chooseFunc = () => {
        switch (approveFunc) {
            case 'del':
                return confirmDel;
            case 'edit':
                return saveUser;
            case 'pass':
                return resetPassUser;
            default:
                return ''
        }
    }

    // Filter logic
    const filteredData = data.filter(user => {
        // Calculate indices for slicing the data array
        // Convert all values of the car object into a single string
        const userValueString = Object.values(user).join(' ').toLowerCase();
        // Check if this string includes the search query
        return userValueString.includes(searchQuery.toLowerCase());
    });
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const handleSort = (key, isNumeric = false) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        const sortedData = data.slice().sort((carA, carB) => {
            // For numeric values
            if (isNumeric) {
                return direction === 'ascending' ? carA[key] - carB[key] : carB[key] - carA[key];
            }
            // For string values
            return direction === 'ascending'
                ? (carA[key] || '').toString().localeCompare((carB[key] || '').toString())
                : (carB[key] || '').toString().localeCompare((carA[key] || '').toString());
        });
        setData(sortedData);
        setSortConfig({key, direction});
    };

    const loadUsers = async () => {
        try {
            const usersData = await usersmanage.getAllUsers();
            if (usersData) {
                // Check if usersData is an array, if not, convert it into an array
                const dataArray = Array.isArray(usersData) ? usersData : [usersData];
                const usersDataFix = await groupmanage.getGroupNameFromArray(dataArray);
                setData(helpers.transformKeys(usersDataFix));
            } else {
                setData([]); // Set to empty array if no data is returned
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isValidPhoneNumber(phoneNumber)) {
            setError('מספר סלולר חייב להכיל 10 ספרות ולהתחיל ב05');
            errorMsg = true
        } else if (!isValidPassword(password)) {
            setError('סיסמא חייבת להיות לפחות 6 תווים ולכלול לפחות אות אחת באנגלית');
            errorMsg = true
        } else {
            errorMsg = false
        }
        // Handle the form submission
        if (!errorMsg && password && phoneNumber) {
            if (logs.isLog()) console.log('Form Submitted');
            const addUser = async () => {
                try {
                    let dataBuild = {
                        privateName: document.getElementById('privateName').value,
                        lastName: document.getElementById('lastName').value,
                        password: password,
                        personalID: document.getElementById('personalId').value,
                        groupID: document.getElementById('groupId').value,
                        phone: phoneNumber,
                    }
                    const addedUser = await usersmanage.addUser(dataBuild)
                    setIsLoading(true);
                    if (addedUser) {
                        loadUsers()
                    }
                } catch (error) {
                    console.error('Error fetching cars:', error);
                }
            }
            addUser()
            // Submit the form data
        }
    };
    const getGroupsForUsers = async () => {
        try {
            const groupData = await groupmanage.getAllGroups();
            if (groupData) {
                let viewAut = cookiesmanage.getCookieVal('viewAuth')
                if (viewAut){
                    setGroupsData(groupData.filter(item => parseInt(item.ID) >= viewAut))
                }else setGroupsData(groupData);
            }
        } catch (error) {
            console.error('Error fetching cars:', error);
        }
    }

    useEffect(() => {
        loadUsers();
        getGroupsForUsers();
    }, []);

    const paginate = pageNumber => setCurrentPage(pageNumber);


    return (
        <div>
            <button onClick={handleAddUserClick}>הוסף משתמש</button>
            {showForm && (
                <Modal isOpen={isModalAddOpen} onClose={closeModalAdd}>
                    <div dir={'rtl'}>
                    <h2>הוספת משתמש חדש</h2>
                        <form onSubmit={handleSubmit}>
                            <input type="text" id="privateName" placeholder="שם פרטי"/>
                            <input type="text" id="lastName" placeholder="שם משפחה"/>
                            <input type="number" id="personalId" placeholder="מספר אישי או תז"/>
                            <input
                                type="text"
                                id="phone-number"
                                placeholder="טלפון"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            <input
                                type="password"
                                placeholder="סיסמא"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Dropdown opts={groupsData} idName='groupId' type={2} pickedVal={4} />
                            {errorMsg && <div style={{color: 'red'}}>{errorMsg}</div>}
                            {/* Add more fields as needed */}
                            <button type="submit">שמור</button>
                            <button onClick={handleCloseAddUserClick}>ביטול</button>
                        </form>
                    </div>
                </Modal>
            )}
            <div>
                <input
                    type="text"
                    placeholder="חפש..." // Placeholder in Hebrew
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <ConfirmBox message={isMessage} onClose={closeModal} onConfirm={chooseFunc()}/>
                </Modal>
                {isLoading ? (
                    <Loading/> // Render the Loading component when data is loading
                ) : (
                    <div className={"car-list-wrapper car-list"}>
                        <DataList title={'רשימת משתמשים'} sortAction={handleSort} data={currentItems}
                                  actions={actionBtns}/></div>
                )}
            </div>
            {!isLoading &&<Pagination itemsPerPage={itemsPerPage} totalItems={data.length} paginate={paginate}
                        currentPage={currentPage}/>}
        </div>
    )
        ;
};

export default Users;