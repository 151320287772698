import React from 'react';
import helpers from "../../services/helpers";

function AlertMessage({ messages }) {
    const sortedMessages = [...messages].sort((a, b) => parseInt(b.time) - parseInt(a.time));
    return (
        <div className={'alert-wrapper'}>
            <div className={'alert-title'}>הכרזות</div>
            {sortedMessages.map((alert, index) => (
                <div  key={index} className={'alert-mess'}>
                    <div className={'top-mess'}>
                        <div>{helpers.formatDate(alert.time)}</div>
                        <div className={'alert-cont'}>{alert.message}</div>
                        <div>מאת {alert.privateName ? alert.privateName +' ' + alert.lastName : 'לא ידוע'}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default AlertMessage;

