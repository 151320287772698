import React from 'react';

function SearchBar({ value, onChange }) {
    return (
        <div className={'search-car'} >

            <input
                type="text"
                className={'search-car-input'}
                placeholder="חפש מס' רכב..."
                value={value} // Use the value prop
                onChange={onChange} // Use the onChange prop
                dir="rtl"
            />
            {/*<button style={styles.searchButton} onClick={() => console.log('Search clicked')}>*/}
            {/*    חיפוש*/}
            {/*</button>*/}
        </div>
    );
}


export default SearchBar;
