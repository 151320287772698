import React from 'react';

function ConfirmBox({ message,onClose,onConfirm }) {
    // Join messages with a separator
    const textStyle = {
        color: 'black', // Set the text color to black
    };
    return (
        <div >
            <div>
                {/* eslint-disable-next-line react/style-prop-object */}
                <h3 style={textStyle}>{message}</h3>
            </div>
            <div>
                {/* eslint-disable-next-line no-unused-expressions */}
                {onConfirm !== '' &&<button onClick={onConfirm} >אישור</button>}
                <button onClick={onClose}>ביטול</button>
            </div>
        </div>
    );
}

export default ConfirmBox;
