import React from 'react';
import cookiesmanage from "../services/cookiesmanage";
import './Navbar.css';
import {Link, useLocation} from "react-router-dom";
import logo2 from "../images/logo4.png";
import AddToHome from "../services/AddToHome";
import subscribemanage from "../services/subscribemanage";

function Navbar({isOpen, innerRef, onNavItemClick,selfPassChange}) {
    const location = useLocation();

    function useCurrentRoute() {
        return location.pathname !== `/` && location.pathname !== '/HomePage';
    }

    function logOff() {
        cookiesmanage.removeAllCookies()
        window.location.reload()
    }

    function isOut() {
        return location.pathname !== `/EntReqOut`;
    }
    function openPassReset(){
        selfPassChange()
    }

    const isAuthenticated = () => {
        return cookiesmanage.getCookieVal('viewAuth')
        // Replace 'authToken' with the actual key of your auth token cookie
    };

    function onSubscribe(){
        subscribemanage.addSubscription()
    }

    return (
        <>
            {isOut() !== false && (
                <nav ref={innerRef} className={`navbar ${isOpen ? 'open' : ''}`} dir="rtl">
                    <ul className="nav-links">
                        {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                        {useCurrentRoute() !== false ? (
                            <>
                                {/*<li  className="nav-item"><SearchNavBar /></li>*/}
                                {/*<li className="nav-item"><Link to="/">עמוד ראשי</Link></li>*/}
                            </>
                        ) : (
                            <></>
                        )}
                        {isAuthenticated() > 0 &&
                            <li className="nav-item nav-logo-item"><Link to="/"><img src={logo2} className="App-logo-home navbar-logo"
                                                                       alt="logo"/></Link></li>}
                        {isAuthenticated() < 4 && isAuthenticated() > 0 &&<li className="nav-item"><Link to="/Cars">רשימת רכבים</Link></li>}
                        {isAuthenticated() > 0 && <li className="nav-item"><Link to="/EntReq">שלח פנייה</Link></li>}
                        {isAuthenticated() < 4 && isAuthenticated() > 0 && (
                            <li className="nav-item"><Link to="/ReqList">בקשות כניסה</Link></li>
                        )}
                        {isAuthenticated() < 3 && isAuthenticated() > 0 && (
                            <>
                                <li  className="nav-item"><Link to="/SendAlert">כתוב התראה</Link></li>
                                <li className="nav-item"><Link to="/Group">קבוצות</Link></li>
                                <li className="nav-item"><Link to="/Users">משתמשים</Link></li>
                            </>
                        )}
                        <li className="nav-item"><a href={'#'} onClick={openPassReset}>שינוי סיסמא</a></li>
                        <li className="nav-item" style={{ color: 'white' }} onClick={logOff}>התנתק</li>
                        {isAuthenticated() < 2 && <li className="nav-item"><AddToHome/></li>}
                        <li className="nav-item" onClick={onSubscribe}><button>הירשם להתראות</button></li>
                    </ul>
                </nav>
            )}
        </>
    );
}

export default Navbar;
