import React, {useEffect, useState} from 'react';
import DataList from "./comps/DataList";
import logs from "../services/logs";
import Modal from "./comps/Modal";
import ConfirmBox from "./comps/ConfirmBox";
import enteryreqmanage from "../services/enteryreqmanage";
import Loading from "./comps/Loading";
import Pagination from "./comps/Pagination";
import helpers from "../services/helpers";
import DatePickerReq from "./comps/DatePickerReq";

const RequestList = () => {
        const actionBtns = [{'name': 'צפייה', 'action': viewTicket}]
        const [data, setData] = useState([]);
        const [isLoading, setIsLoading] = useState(false); // State to track loading status
        const [searchQuery, setSearchQuery] = useState('');
        const [sortConfig, setSortConfig] = useState({key: null, direction: 'ascending'});
        const [currentPage, setCurrentPage] = useState(1);
        const [itemsPerPage, setItemsPerPage] = useState(300);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [isMessage, setMessage] = useState('')

        const paginate = pageNumber => setCurrentPage(pageNumber);
        const closeModal = () => {
            setIsModalOpen(false)
        }

        const keysToKeep = ['מצב בקשה', 'תאריך בקשה', 'משתמש מעדכן', 'מספר רכב', 'שם פרטי', 'סלולר', 'בקבוצה', 'סיבה'];

        const filteredData = data.filter(car => {
            // Convert all values of the car object into a single string
            const carValuesString = Object.values(car).join(' ').toLowerCase();
            // Check if this string includes the search query
            return carValuesString.includes(searchQuery.toLowerCase());
        }).map(car => {
            // Create a new object with only the keys specified in keysToKeep
            const filteredCar = {};
            keysToKeep.forEach(key => {
                filteredCar[key] = car[key];
            });
            return filteredCar;
        });

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
        const handleSort = (key, isNumeric = false) => {
            let direction = 'ascending';
            if (sortConfig.key === key && sortConfig.direction === 'ascending') {
                direction = 'descending';
            }

            const sortedData = data.slice().sort((carA, carB) => {
                // For numeric values
                if (isNumeric) {
                    return direction === 'ascending' ? carA[key] - carB[key] : carB[key] - carA[key];
                }
                // For string values
                return direction === 'ascending'
                    ? (carA[key] || '').toString().localeCompare((carB[key] || '').toString())
                    : (carB[key] || '').toString().localeCompare((carA[key] || '').toString());
            });
            setData(sortedData);
            setSortConfig({key, direction});
        };

        function findMatchingId(array, objToMatch) {
            const matchingItem = array.find(item =>
                item["מספר רכב"] === objToMatch["מספר רכב"] &&
                item["סלולר"] === objToMatch["סלולר"] &&
                item["תאריך בקשה"] === objToMatch["תאריך בקשה"]
            );

            return matchingItem ? matchingItem["סידורי"] : null;
        }

        function viewTicket(item) {
            if (logs.isLog()) console.log('צפייה', item)
            setIsModalOpen(true)
            const findTicket = async () => {
                try {
                    const detailsOfTicket = await enteryreqmanage.getDetailedRequest(findMatchingId(data, item), 1)
                    setIsModalOpen(false)
                    if (detailsOfTicket) {
                        setIsModalOpen(true)
                        setMessage(<>
                            {handleTicketView(detailsOfTicket)}
                            <div>
                                <button onClick={() => handleTicketReview(2, detailsOfTicket)}>אשר בקשה</button>
                                <button onClick={() => handleTicketReview(1, detailsOfTicket)}>דחה בקשה</button>
                            </div>
                        </>)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            findTicket()
        }

        function handleTicketView(carInfo) {
            const formatDate = (timestamp) => {
                const date = new Date(parseInt(timestamp));
                return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
            };
            return (
                <div dir={'rtl'}>
                    <div><h4>פרטי הרכב והבקשה</h4></div>
                    <div>שיוך למחלקה: {carInfo.departmentName || "לא צוין"}</div>
                    <div>שם פרטי: {carInfo.ownerFirstName || "לא צוין"}</div>
                    <div>שם משפחה: {carInfo.ownerLastName || "לא צוין"}</div>
                    <div>סוג שירות: {carInfo.personGroupName || "לא צוין"}</div>
                    <div>מספר רכב: {carInfo.carID || "לא צוין"}</div>
                    <div>חברת יצור: {carInfo.carManufacturer || "לא צוינה"}</div>
                    <div>צבע: {carInfo.carColor || "לא צוין"}</div>
                    <div>לוחית רישוי: {carInfo.plateType === "1" ? "אזרחית" : carInfo.plateType === "2" ? 'צבאית' : 'משטרתית' }</div>
                    <div>מצב בקשה: {carInfo.requestStatus === "1" ? "מאושרת" : "לא מאושרת"}</div>
                    <div>מעדכן אחרון: {carInfo.userID === "0" ? "אורח" : carInfo.userID}</div>
                    <div>תאריך בקשה: {carInfo.requestType == '1' ? "קבוע" : carInfo.requestType == '3' ? 'זמני' : 'נדחה'}</div>
                    <div>תאריך התחלה: {carInfo.startDate != 0 ? formatDate(carInfo.startDate) : '-'}</div>
                    <div>תאריך סיום: {carInfo.endDate != 0 ? formatDate(carInfo.endDate) : '-'}</div>
                    <div>סיבה לבקשה: {carInfo.reason || "לא צוינה"}</div>
                </div>
            );
        }

        function makeDatePicker(data) {
            setMessage(
                <div dir={'rtl'}>
                    <DatePickerReq sTid='carStartT' eTid='carEndT'/>
                    <button onClick={()=> sendCustomApprove(data)}>אשר כניסה זמנית</button>
                </div>
            )
        }

        function getApproveTypes(data) {
            switch (data.requestType) {
                case '1':
                    return (
                        <>
                            <button onClick={() => approveThisTicket(data, 1)}>אישור קבוע</button>
                            <button onClick={() => makeDatePicker(data)}>אישור זמני לבחירה</button>
                        </>
                    )
                case '3':
                    return (
                        <div>
                            <button onClick={() => approveThisTicket(data, 1)}>אישור קבוע</button>
                            <button onClick={() => makeDatePicker(data)}>אישור זמני לבחירה</button>
                            <button onClick={() => approveThisTicket(data, 3)} dir={'rtl'}><span>אישור זמני </span><span
                                dir={'rtl'}>מ{helpers.formatDate(data.startDate)}</span><span>עד ל{helpers.formatDate(data.endDate)}</span>
                            </button>
                        </div>)
            }
        }


        const handleTicketReview = (type, data) => {
            console.log('TEST HERE:', data)
            if (type !== 1) {
                setMessage(
                    <>
                        <div dir={'rtl'}>
                            האם ברצונך לאשר את הבקשה של <span
                            style={{color: 'red'}}>{data.ownerFirstName + ' ' + data.ownerLastName}</span> ?
                        </div>
                        <div>
                            {getApproveTypes(data)}
                        </div>
                    </>)
            } else {
                setMessage(
                    <>
                        <div dir={'rtl'}>
                            האם ברצונך לדחות את הבקשה של <span
                            style={{color: 'red'}}>{data.ownerFirstName + ' ' + data.ownerLastName}</span> ?
                        </div>
                        <div>
                            <button onClick={() => declineTicket(data)}>דחה בקשה (חסום רכב)</button>
                        </div>
                    </>)
            }

            setIsModalOpen(true)
        }

        function sendCustomApprove(data){
            data.startDate = helpers.convertToUnixMilliseconds(document.getElementById('carStartT').value)
            data.endDate = helpers.convertToUnixMilliseconds(document.getElementById('carEndT').value)
            approveThisTicket(data,3)
        }

        const approveThisTicket = async (data, type) => {
            const ticketApprove = async (data, type, isOverWrite = null) => {
                try {
                    let dataBuild = {
                        requestStatus: type === 1 ? '1' : '3',
                        ID: data.ID,
                        startDate: type === 1 ? '0' : data.startDate,
                        endDate: type === 1 ? '0' : data.endDate
                    }
                    let reqData = await enteryreqmanage.requestDecision(dataBuild, isOverWrite);
                    setIsLoading(true);
                    setIsModalOpen(false)
                    if (reqData) {
                        if (reqData == 'dupl') {
                            setIsModalOpen(true)
                            setMessage(
                                <div dir={'rtl'}>
                                    רכב זה כבר הוכנס למערכת בעבר, האם לשנות להגדרות שנבחרו שוב?
                                    <button onClick={() => ticketApprove(data, type, 1)}>אשר בכל זאת</button>
                                </div>)
                        } else {
                            fetchRequests()
                        }
                    }
                } catch (error) {
                    console.error('Error fetching cars:', error);
                }
            }
            await ticketApprove(data, type)
        };

        const declineTicket = async (data) => {
            const savedReq = async (data, type = null) => {
                try {
                    let dataBuild = {
                        requestStatus: "2",
                        ID: data.ID,
                        startDate: "0",
                        endDate: "0"
                    }
                    console.log('check here data sent:', dataBuild)
                    let reqData = await enteryreqmanage.requestDecision(dataBuild, type);
                    setIsLoading(true);
                    setIsModalOpen(false)
                    if (reqData) {
                        if (reqData === 'dupl') {
                            setIsModalOpen(true)
                            setMessage(
                                <div dir={'rtl'}>
                                    רכב זה כבר הוכנס למערכת בעבר, האם לשנות להגדרות שנבחרו שוב?
                                    <button onClick={() => savedReq(data, 1)}>חסום בכל זאת</button>
                                </div>)
                        } else {
                            fetchRequests()
                        }
                    }
                } catch (error) {
                    console.error('Error fetching cars:', error);
                }
            }
            await savedReq(data);
        };


        const fetchRequests = async () => {
            try {
                const reqData = await enteryreqmanage.getAllEnteryReq();
                if (reqData) {
                    let reqDataList = Array.isArray(reqData) ? reqData : [reqData]
                    setData(helpers.transformKeys(enteryreqmanage.fixReqTable(reqDataList)));
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            } finally {
                setIsLoading(false); // Stop loading
            }
        };

        useEffect(() => {
            fetchRequests();
        }, []);
        return (
            <div>
                <input
                    type="text"
                    className={'search-car-input'}
                    placeholder="חפש..." // Placeholder in Hebrew
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <ConfirmBox message={isMessage} onClose={closeModal} onConfirm={''}/>
                </Modal>
                <div className={"car-list-wrapper car-list"}>
                    {isLoading ? (
                        <Loading/> // Render the Loading component when data is loading
                    ) : (
                        <>
                            <DataList title={'רשימת בקשות כניסה'} data={currentItems} sortAction={handleSort}
                                      actions={actionBtns}/>
                            <Pagination itemsPerPage={itemsPerPage} totalItems={data.length} paginate={paginate}
                                        currentPage={currentPage}/>
                        </>
                    )}
                </div>
            </div>
        );
    }
;

export default RequestList;