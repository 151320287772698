import datamanage from "./datamanage";
import helpers from "./helpers";

async function getAllUsers() {
    let isValid = _isUsersListSaved()
    if (!isValid) {
        try {
            let data = {'authentication': {"key": helpers.getToken()}, 'data': {}, 'type': {'act': 'usersList'}}
            let response = await datamanage.sendData(data, 'sendback')
            if(response){
                helpers.saveSession('users-list', response)
                const dataArray = Array.isArray(response) ? response : [response];
                return _fixStatusView(dataArray)
            }else return null
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    } else {
        return isValid
    }
}


function _fixStatusView(array) {
    return array.map(item => {
        return {
            ...item,
            status: item.status == 1 ? 'פעיל' : 'מושעה'
        }
    })
}

function _isUsersListSaved() {
    let isUsersList = helpers.isSessionValid('users-list')
    if (isUsersList) return isUsersList
    else return false
}


async function addUser(userInfo) {
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': userInfo, 'type': {'act': 'addUser'}}
        return await datamanage.sendData(data, 'sendback')
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}



function generatePass() {
    let numbers = '';
    for (let i = 0; i < 7; i++) {
        numbers += Math.floor(Math.random() * 10); // Random digit from 0 to 9
    }
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const randomLetter = letters[Math.floor(Math.random() * letters.length)];
    const password = numbers + randomLetter;
    // Shuffle the password
    return password.split('').sort(() => 0.5 - Math.random()).join('');
}

 async function getEnteryInfoByUserId(id) {
     // Fetch all users
     const users = await getAllUsers();
     // Check if users list is not empty
     if (users && users.length > 0) {
         const user = users.find(user => user.ID === id);
         return user || null;
     }
     return null;
 }


async function blockUser(userInfo) {
        try {
            let data = {'authentication': {"key": helpers.getToken()}, 'data': userInfo, 'type': {'act': 'changeUserStatus'}}
            return await datamanage.sendData(data, 'sendback')
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }
async function editUser(userInfo){
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': userInfo, 'type': {'act': 'editUser'}}
        return await datamanage.sendData(data, 'sendback')
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}
async function editPass(userInfo){
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': userInfo, 'type': {'act': 'editUserPassword'}}
        return await datamanage.sendData(data, 'sendback')
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}

export default {
    addUser,
    editUser,
    getAllUsers,
    editPass,
    blockUser,
    generatePass,
}