import './App.css';
import React from "react";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import Cookiesmanage from "./services/cookiesmanage";
import {Routes, Route} from "react-router-dom";
import Navbar from "./navbar/NavBar";
import Cars from "./pages/Cars";
import Users from "./pages/Users";
import EntranceReq from "./pages/EntranceReq";
import RequestList from "./pages/RequestList"
import SendAlert from "./pages/SendAlert";
import Error404 from "./pages/Error404";
import logs from "./services/logs";
import Departments from "./pages/Departments";
import Modal from "./pages/comps/Modal";
import ConfirmBox from "./pages/comps/ConfirmBox";
import QrRead from "./pages/QrRead";
import Loading from "./pages/comps/Loading";
import QrComp from "./pages/comps/QrComp";
import datamanage from "./services/datamanage";
import helpers from "./services/helpers";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: Cookiesmanage.getCookie('loginKey'),
            isNavbarOpen: false,
            isModalOpen: false,
            isMessage: null,
            isLoading: false,
            phone: null,
        };
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if(this.state.isLogin  && !prevState.isLogin) this.startSession()
    // }
    //
    // componentWillUnmount() {
    //     // Cleanup: close the EventSource connection if it exists
    //     if (this.eventSource) {
    //         this.eventSource.close();
    //     }
    // }


    setPhone = (phoneVal) => {
        this.setState({phone: phoneVal})
    }
    toggleNavbar = () => {
        this.setState({isNavbarOpen: !this.state.isNavbarOpen});
    };

    loginAccess = async (res = null) => {
        if (res.type && res.type === 'error') {
            this.setState({
                isMessage: res.text,
                isModalOpen: true
            })
        } else if (res.type && res.type === 'oldPass') {
            this.setState({
                isMessage: <>
                    <div>{res.text}</div>
                    <div>{this.makeRestPassDiv}</div>
                </>,
                isModalOpen: true
            })
        } else {
            this.setState({isLoading: true});
            if (logs.isLog()) console.log('token:', res.token)
            await Cookiesmanage.setCookie('authToken', res.token, 8) // בדיקת הרשאה
            await Cookiesmanage.setCookie('viewAuth', res.groupID, 8) // בדיקת שיוך התראה
            await Cookiesmanage.setCookie('loginKey', true, 8) // האם מחובר
            this.setState({isLogin: true, isLoading: false}); // Reset loading state
        }
    };

    handleClickOutside = (event) => {
        // Assuming your navbar has a ref called `navbarRef`
        if (this.navbarRef && !this.navbarRef.contains(event.target)) {
            this.setState({isNavbarOpen: false});
        }
    };
    closeNavbar = () => {
        this.setState({isNavbarOpen: false});
    }

    setNavbarRef = (node) => {
        this.navbarRef = node;
    };
    closeModal = () => {
        this.setState({isModalOpen: false})
    }

    makeRestPassDiv = () => {
        return <>
            <input type={"password"} id={'oldPass'} placeholder={'סיסמא ישנה'}/>
            <input type={"password"} id={'newPass'} placeholder={'סיסמא חדשה'}/>
            <button onClick={this.sendResetPass}>שמור</button>
        </>
    }
    selfPassChange = () => {
        this.setState({isModalOpen: true})
        this.setState({isMessage: this.makeRestPassDiv()})
    }
    sendResetPass = () => {
        let data = {
            'data': {
                'phone': this.state.phone,
                'oldPassword': document.getElementById('oldPass').value,
                'newPassword': document.getElementById('newPass').value
            },
            'type': {'act': 'resetPassword'}
        }
        datamanage.sendData(data, 'reset')
    }

    render() {
        if (this.state.isLoading) {
            return <Loading/>;
        }
        return (
            <div className="App">
                <div>
                    <Modal isOpen={this.state.isModalOpen} onClose={this.closeModal}>
                        <ConfirmBox message={this.state.isMessage} onClose={this.closeModal}
                                    onConfirm={''}/>
                    </Modal>
                    {this.state.isLogin &&
                        <div>
                            <div className="hamburger" onClick={this.toggleNavbar}>
                                {/* Hamburger Icon */}
                                <span>☰</span>
                            </div>
                            <Navbar innerRef={this.setNavbarRef} selfPassChange={this.selfPassChange}
                                    isOpen={this.state.isNavbarOpen}/>
                            <div className={`main-content ${this.state.isNavbarOpen ? 'shifted' : ''}`}>
                                <Routes>
                                    <Route path={'/'} element={<HomePage />}/>
                                    <Route path={'/Cars'} element={<Cars/>}/>
                                    <Route path={'/Users'} element={<Users/>}/>
                                    <Route path={'/EntReq'} element={<EntranceReq/>}/>
                                    <Route path={'/ReqList'} element={<RequestList/>}/>
                                    <Route path={'/SendAlert'} element={<SendAlert/>}/>
                                    <Route path={'/Group'} element={<Departments/>}/>
                                    <Route path={'/QrRead/*'} element={<QrRead/>}/>
                                    <Route path={'/QrCar'} element={<QrComp/>}/>
                                </Routes>
                            </div>
                        </div>
                    }
                </div>
                {!this.state.isLogin && <>
                    <div className={'login-header'}>
                        <Routes>
                            <Route path={'/EntReqOut'} element={<EntranceReq/>}/>
                            <Route path={'/QrCar/*'} element={<QrComp/>}/>
                            <Route path={'/'} element={<LoginPage phoneSet={this.setPhone} LogOn={this.loginAccess}/>}/>
                            <Route path={'*'} element={<Error404 reason={'קישור שגוי'}/>}/>
                        </Routes>
                    </div>
                </>}
                <footer>נוצר ע"י Lega6 2023</footer>
            </div>
        )
    }
}

export default App;
