import React, { useState } from 'react';
import logs from "../services/logs";
import logo2 from "../images/logo3.png";
import "../css/loginpage.css";
import datamanage from "../services/datamanage";
import {Link} from "react-router-dom";

function  LoginPage(props) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // Function to validate phone number
    const isValidPhoneNumber = (phone) => {
        const isNumeric = /^\d+$/.test(phone);
        return isNumeric && phone.startsWith('05') && phone.length === 10;
    };

    // Function to handle final submission (including password)
    const handleFinalSubmit = (event) => {
        event.preventDefault();
        setError('');
        if (!isValidPhoneNumber(phoneNumber)) {
            setError('מספר סלולר חייב להכיל 10 תווים ולהתחיל ב05');
        }else{
            // Here, handle the login logic with phoneNumber and password
            if (logs.isLog()) console.log('Phone Number:', phoneNumber, 'Password:', password);
            let data = {
                'data':{'phone':phoneNumber,
                    'password':password},
                'type':{'act':'login'}}
            props.phoneSet(phoneNumber)
            datamanage.sendData(data,props.LogOn)
        }
    };

    return (
        <form className={'login-header'} dir={'rtl'} onSubmit={handleFinalSubmit}>
            <div className={'login-container'}>
            <img src={logo2} className="App-logo logo-login"  alt="logo"/>

            <div className={'login-input-continer'}>
                    {/*<label htmlFor="phone-number">מספר סלולר</label>*/}
                    <input
                        type="text"
                        className={'input-login'}
                        id="phone-number"
                        value={phoneNumber}
                        placeholder="מספר פלאפון"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </div>
            <div className={'login-input-continer'}>
                {/*<label htmlFor="password">סיסמא:</label>*/}
                <input
                    type="password"
                    className={'input-login'}
                    id="password"
                    value={password}
                    placeholder="סיסמא"
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div >
                <div className={'login-input-continer'}>
            <button className={'button-login'} type="submit">התחברות</button>
            {error && <div style={{ color: 'black', fontSize: '18px', marginTop: '20px' }}>{error}</div>}
                </div>
            </div>
            <div> לשליחת בקשת כניסה : <Link to={'EntReqOut'}>לחץ כאן</Link></div>
        </form>
    );
}
export default LoginPage;