import helpers from "./helpers";
import datamanage from "./datamanage";

async function getAllPersonalGroups(){
    let isValid = _isPGListSaved()
    if(!isValid){
        try {
            let data = {'authentication':{"key": helpers.getToken()},'data':{},'type':{'act':'personGroupList'}}
            let response =  await datamanage.sendData(data, 'sendback')
            helpers.saveSession('pg-list',response)
            return response
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }else {
        return  isValid
    }
}

function _isPGListSaved(){
    let isPGList = helpers.isSessionValid('pg-list')
    if(isPGList) return isPGList
    else return false
}

export default {
    getAllPersonalGroups
}