import datamanage from "./datamanage";
import helpers from "./helpers";


async function delDept(deptInfo){
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': deptInfo, 'type': {'act': 'changeDepartmentStatus'}}
        return await datamanage.sendData(data, 'sendback')
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}

async function editDept(carInfo,failBackFunc){
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': carInfo, 'type': {'act': 'editDepartment'}}
        const savedDept = await datamanage.sendData(data, 'sendback')
        if(savedDept.problem){
            failBackFunc(savedDept)
        }else return savedDept
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}


async function addDept(name,failBackFunc){
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': {'name':name}, 'type': {'act': 'addDepartment'}}
        const savedDept = await datamanage.sendData(data, 'sendback')
        if(savedDept.problem){
            failBackFunc(savedDept)
        }else return savedDept
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}

async function getAllDeptsList(){
    let isValid = _isDeptListSaved()
    if(!isValid){
        try {
            let data = {'authentication':{"key": helpers.getToken()},'data':{},'type':{'act':'departmentsList'}}
            let response =  await datamanage.sendData(data, 'sendback')
            if(response){
                helpers.saveSession('dept-list',response)
                return _removeStatusProperty(response)
            }else return null
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }else {
        return  isValid
    }
}

async function getDeptNameFromArray(array) {
    let isDeptList = helpers.isSessionValid('dept-list');
    let deptList = isDeptList ? isDeptList : await getAllDeptsList();
    if (deptList) {
        const arrayData = Array.isArray(array) ? array : [array];
        const arrayDeptData = Array.isArray(deptList) ? deptList : [deptList];
        return _addDepartmentNamesToCars(arrayData, arrayDeptData);
    }
    return array; // Return the original data if deptList is not available
}

function _addDepartmentNamesToCars(arrayData, departments) {
    const processedData = arrayData.map(item => {
        const department = departments.find(dept => dept.ID === item.departmentID);
        return {
            ...item,
            departmentID: department ? department.departmentsName : 'לא נבחר'
        };
    });
    return Array.isArray(arrayData) ? processedData : processedData[0];
}


function _removeStatusProperty(data) {
    if (Array.isArray(data)) {
        return data.map(({ status, ...rest }) => rest);
    }
    const { status, ...rest } = data;
    return rest;
}

function _isDeptListSaved(){
    let isDeptList = helpers.isSessionValid('dept-list')
    if(isDeptList) return isDeptList
    else return false
}


export default {
    getAllDeptsList,
    getDeptNameFromArray,
    addDept,
    delDept,
    editDept
};
