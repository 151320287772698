import logs from "./logs";
import cookiesmanage from "./cookiesmanage";

function getCurrentDate() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
    const year = date.getFullYear().toString().substr(-2);
    if(logs.isLog()) console.log(`${day}/${month}/${year}`)
    return `${year}/${month}/${day}`;
}

const _keyMapping = {
    ID: 'סידורי',
    carID: 'מספר רכב',
    requestStatus: 'מצב בקשה',
    perosnalIDNum: 'מספר זיהוי',
    reason: 'סיבה',
    requestType: 'סוג כניסה מבוקש',
    userID: 'משתמש מעדכן',
    departmentName: 'בקבוצה',
    time: 'תאריך בקשה',
    personalID: 'מספר זיהוי',
    departmentsName: 'שם מחלקה',
    carColor: 'צבע',
    status: 'סטטוס',
    carManufacturer: 'סוג רכב',
    departmentID: 'שיוך ל',
    ownerFirstName: 'שם פרטי',
    privateName: 'שם פרטי',
    ownerLastName: 'שם משפחה',
    lastName: 'שם משפחה',
    isMilitary: 'האם צבאי',
    startDate: 'תאריך התחלה',
    endDate: 'תאריך סיום',
    ownerPhone: 'סלולר',
    phone: 'סלולר',
    groupName: 'שם קבוצה',
    groupID: 'זיהוי קבוצה',
    userCount: 'כמות משתמשים',
    ownerID: 'תז\\מא בעל הרכב',
    // ... other key mappings
};

function transformKeys(dataArray) {
    return dataArray.map(item => {
        const newItem = {};
        Object.keys(item).forEach(key => {
            // Use the new key from keyMapping if it exists, otherwise use the original key
            const newKey = _keyMapping[key] || key;
            newItem[newKey] = item[key];
        });
        return newItem;
    });
}

function saveSession(key,data){
    const sessionData = {
        ...data,
        timestamp: new Date().getTime() // Save the current timestamp
    };
    localStorage.setItem(key, JSON.stringify(sessionData));
}

function _getSession(key){
    return JSON.parse(localStorage.getItem(key))
}

function isSessionValid(key) {
    const sessionData = _getSession(key);
    if (sessionData) {
        const savedTime = sessionData.timestamp;
        if (savedTime) {
            const currentTime = new Date().getTime();
            const oneHour = 1000 * 60 * 60; // Time in milliseconds
            if (currentTime - savedTime < oneHour) {
                return sessionData.data; // Session is valid
            }
        }
    }
    return false; // Session is not valid
}
function getToken(){
    return cookiesmanage.getCookieVal('authToken')
}

function isTodayBetween(startTimestamp, endTimestamp){
    const currentTimestamp = new Date().getTime(); // Current Unix timestamp in milliseconds
    return currentTimestamp >= startTimestamp && currentTimestamp <= endTimestamp;
}

function formatDate(timestamp) {
    const date = new Date(parseInt(timestamp));
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-11
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    // Pad single digit month, day, hour, and minute with a leading zero
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
    if(year !== 1970) return `${formattedDay}/${formattedMonth}/${year} ${formattedHour}:${formattedMinute}`;
    else return  'אישור קבוע'
}

function convertToUnixMilliseconds(dateString) {
    // Assuming dateString is in "DD/MM/YYYY HH:mm" format
    const parts = dateString.split(/[/ :]/); // Split by both slash and colon
    // Adjust the month (-1) because months are 0-indexed in JavaScript
    const date = new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]);
    return date.getTime();
}
function getCurrentPath() {
    return window.location.pathname;
}
export default {
    formatDate,
    getCurrentPath,
    convertToUnixMilliseconds,
    getCurrentDate,
    transformKeys,
    saveSession,
    getToken,
    isTodayBetween,
    isSessionValid
}


