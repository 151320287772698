import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import helpers from "../services/helpers";
import datamanage from "../services/datamanage";
import Loading from "./comps/Loading";

const QrRead = () => {
    const [showCar, setShowCar] = useState(<Loading/>);
    const location = useLocation();

    function findParams() {
        const queryParams = new URLSearchParams(location.search);
        getCarDet(queryParams.get('searchLabel'))
    }

    async function getCarDet(token) {
        try {
            let data = {
                'authentication': {"key": helpers.getToken()},
                'data': {'token': token},
                'type': {'act': 'getCarInfoByToken'}
            };
            const savedCarDet = await datamanage.sendData(data, 'sendback');
            if (savedCarDet) setShowCar(makeCarVisual(savedCarDet));
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setShowCar(<>לא נמצא רכב זה</>)
        }
    }

    function _isApproved(status,endTime= 0,startTime = 0){
        switch (status){
            case '1':
                return <span style={{ color: 'greenyellow' }}>אישור כניסה קבוע</span>
            case '2':
                return <span style={{ color: 'orangered' }}>רכב חסום כניסה</span>
            case '3':
                if(helpers.isTodayBetween(startTime,endTime)) return <span style={{ color: 'greenyellow' }}>מאושר כניסה עד {helpers.getCurrentDate(endTime)}</span>
                else return <span style={{ color: 'orangered' }}>הכניסה של רכב זה כבר לא בתוקף\עדיין לא נכנסה לתוקף</span>
            default:
                return <span style={{ color: 'blueviolet' }}>לא נמצאו פרטי אישור לרכב זה - פנה לאחראי</span>
        }
    }
    function makeCarVisual(data) {
        return (
            <>
                <div>
                    <h1>{_isApproved(data.status,data.endDate,data.startDate)}</h1>
                </div>
                <div>
                    <span>מספר הרכב: </span>
                    <span>{data.plateType == '1' ? '' : data.plateType == '2' ? 'צ-' : 'מ-'} {data.carID}</span>
                </div>
                <div>
                    <span>סוג הרכב וצבע: </span>
                    <span>{data.carManufacturer + ' ' + data.carColor}</span>
                    <span>({data.plateType == '1' ? 'רכב אזרחי' : data.plateType == '2' ? 'רכב צבאי' : 'רכב משטרתי'})</span>
                </div>
                <div>
                    <span>בעל הרכב: </span>
                    <span>{data.ownerFirstName + ' ' + data.ownerLastName}</span>
                </div>
                <div>
                    <span>סוג שירות: </span>
                    <span>{data.personGroupName}</span>
                </div>
                <div>
                    <span>עדכון אחרון ע"י: </span>
                    <span>{data.approverFirstName + ' ' + data.approverLastName}</span>
                </div>
            </>
        )
    }

    useEffect(() => {
        findParams()
    }, []);

    return (
        <div style={{ backgroundColor: 'floralwhite',width:'40%',padding:'1rem',marginLeft:'25%'}}>
            <div>{showCar}</div>
        </div>
    );
}


export default QrRead;