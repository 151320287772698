import React, {useEffect, useState} from 'react';
import SearchBar from './comps/SearchBar';
import Card from './comps/Card';
import logounit from "../images/Almar_5002.png";
import CarDetailsPage from "./CarDetailsPage";
import carmanage from "../services/carmanage";
import '../css/homepage.css'
import helpers from "../services/helpers";
import AlertMessage from "./comps/AlertMessage";
import alertsmanage from "../services/alertsmanage";
import LastChangesCars from "../services/LastChangesCars";
import datamanage from "../services/datamanage";

function HomePage(props) {
    const [clickedCardId, setClickedCardId] = useState(null);
    const [data, setData] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [moreDetails, setMoreDetails] = useState('')
    const [alerts, setAlerts] = useState('')
    const searchResults = handleSearch(searchQuery);
    const [lastInfo, setLastInfo] = useState(null);

    function handleSearch(query) {
        if (!query) {
            return [];
        }

        // Filter the data array for matching 'carID'
        const searchResults = data
            .filter(car =>
                car['מספר רכב'] && car['מספר רכב'].toString().includes(query)
            )
            .slice(0, 4); // Limit to 4 results
        // Map the results to include 'carID', 'ownerFirstName', 'ownerLastName', etc.
        return searchResults.map(car => {
                let statusToShow = (car) => {
                    if (parseInt(car['סטטוס']) == 1) return 'כן'
                    else if (parseInt(car['סטטוס']) == 2) return 'לא'
                    if(helpers.isTodayBetween(car['תאריך התחלה'],car['תאריך סיום'])) return 'כן'
                    else return 'לא'
                }
            const plateT = car.plateType == '1' ? '' : car.plateType == '2' ? 'צ-' : 'מ-'
                return {
                    title: car['מספר רכב'] ? plateT + car['מספר רכב'].toString() : '',
                    content: `${car['שם פרטי'] ? car['שם פרטי'] : ''} ${car['שם משפחה'] ? car['שם משפחה'] : ''}`,
                    content2: statusToShow(car),
                    id: car['מספר רכב'],
                };
            }
        )
            ;
    }
    const handleCardClick = (id) => {
        setClickedCardId(id);  // Set the clicked card ID
        setCardClass('cardOpen');  // Set the card class for animation or styling
        setTimeout(() => {
            setMoreDetails(id)
            setSearchQuery('')
        }, 300);  // Delay for animation
    };
    const [cardClass, setCardClass] = useState(null)
    const fetchCars = async () => {
        try {
            const carsData = await carmanage.getAllCarsList();
            if (carsData) {
                 let carList = Array.isArray(carsData) ? carsData : [carsData]
                setData(helpers.transformKeys(carList));
            }
            else{
                setData([])
            }
        } catch (error) {
            console.error('Error fetching cars:', error);
        }
    };
    const askSession = async () => {
        document.cookie = 'PHPSESSID=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        try {
            let data = {
                'authentication': {"key": helpers.getToken()},  // Ensure helpers.getToken() is correctly imported and available
                'data': {},
                'type': {'act': 'liveUpdates', 'returnInput': '1'}
            };
            const sessionAns = await datamanage.sendData(data, 'sendback'); // Ensure datamanage.sendData is correctly imported and available
            if (sessionAns && sessionAns.data && sessionAns.data.data && sessionAns.data.data.liveToken) {
                return sessionAns.data.data.liveToken; // Make sure the response structure is as expected
            }
        } catch (error) {
            console.error('Failed to fetch token:', error);
        }
    }
    const startSession = async () => {
        const token = await askSession();
        if (token) {
            const eventSource = new EventSource(`https://oto.lega6.com/php/index.php?act=liveUpdatesToken&token=${token}`);
            eventSource.onmessage = (event) => {
                let data = JSON.parse(event.data);
                let dataFix = JSON.parse(data.data['liveUpdates']);
                if (dataFix) {
                    setLastInfo(dataFix);
                }
            };
            eventSource.onerror = (event) => {
                console.error("EventSource failed:", event);
            };
            return () => {
                eventSource.close();
            };
        }
    };
    useEffect(() => {
        startSession();
        return () => {};
    }, []);
    const fetchAlerts= async () => {
        try {
            const alertsData = await alertsmanage.getAllAlerts();
            if (alertsData) {
                setAlerts(alertsData);
            }else setAlerts([])
        } catch (error) {
            console.error('Error fetching cars:', error);
        }
    };

    useEffect(() => {
        fetchCars();
        fetchAlerts();
    }, []);



    return (
        <div>
            <img src={logounit} className="App-logo-home logo-unit" alt="logo"/>
            {
                alerts && (Array.isArray(alerts) ? alerts.length > 0 : alerts) ? (
                    <AlertMessage messages={Array.isArray(alerts) ? alerts : [alerts]} />
                ) : (
                    <div className={'search-wrapper'}>אין הכרזות</div>
                )
            }
            <div className={'cards-wrapper'}>
                <LastChangesCars data={lastInfo} pickCar={handleCardClick}/>
            </div>
            <div className={'search-wrapper'}>
                <SearchBar value={searchQuery} onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setMoreDetails('');
                    setCardClass('');
                    setClickedCardId(null);
                }}/>
            </div>
            {!moreDetails && searchResults.length > 0 && <div className={'cards-wrapper'}>
                {searchResults.length > 0 ? (
                    searchResults.map((item, index) => (
                        <Card key={index}
                              cardClass={item.id === clickedCardId ? cardClass : null}
                              title={`מספר רכב: ${item.title}`}
                              content={`שם מלא: ${item.content}`}
                              content2={` האם יש אישור: ${item.content2}`}
                              openCard={() => handleCardClick(item.id)}/>))
                ) : searchQuery && (
                    <p>לא נמצאו רכבים</p>
                )}
            </div>}
            {moreDetails && <div className={'car-details-wrapper'}>
                <CarDetailsPage id={moreDetails}/>
            </div>}
        </div>
    );
}


export default HomePage;