import DataList from "./comps/DataList";
import {useState, useEffect} from 'react';
import logs from "../services/logs";
import carmanage from "../services/carmanage";
import Dropdown from "./comps/DropDown";
import '../css/carlist.css';
import helpers from "../services/helpers";
import Loading from "./comps/Loading";
import Pagination from "./comps/Pagination";
import Modal from "./comps/Modal";
import ConfirmBox from "./comps/ConfirmBox";
import departmanage from "../services/departmanage";
import DatePickersValid from "./comps/DatePickersValid";
import persongroupmanage from "../services/persongroupmanage";
import cookiesmanage from "../services/cookiesmanage";
import addcarbtn from "../images/sport-car.svg";
import historyImg from '../images/history.svg';
import editImg from '../images/pencil.svg';


function Cars() {
    const actionBtns = [
        {'name': historyImg,'type':1, 'action': openHistory},
        {'name': editImg, 'type':1, 'action': editCar}
    ]
    const [showForm, setShowForm] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // State to track loading status
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({key: null, direction: 'ascending'});
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(90);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const [approveFunc, setApproveFunc] = useState(null)
    const [isMessage, setMessage] = useState('')
    const [deptData, setDeptData] = useState([])
    const [chx, setChx] = useState(false);
    const [formDiv, setformDiv] = useState('');
    const [perGroup, setPerGroupsData] = useState([])
    const [editFormData, setEditFormData] = useState(null);


    const paginate = pageNumber => setCurrentPage(pageNumber);
    const closeModal = () => {
        setIsModalOpen(false)
    }
    const closeModalAdd =() =>{
        setIsModalAddOpen(false)
    }
    // Filter logic
    // Define the keys you want to keep
    const keysToKeep = ['סטטוס', 'מספר רכב', 'שם פרטי', 'סלולר', 'שיוך ל', 'סוג רכב', 'צבע'];

    const filteredData = data.filter(car => {
        // Convert all values of the car object into a single string
        const carValuesString = Object.values(car).join(' ').toLowerCase();
        // Check if this string includes the search query
        return carValuesString.includes(searchQuery.toLowerCase());
    }).map(car => {
        // Create a new object with only the keys specified in keysToKeep
        const filteredCar = {};
        keysToKeep.forEach(key => {
            filteredCar[key] = car[key];
        });
        return filteredCar;
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const handleSort = (key, isNumeric = false) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sortedData = data.slice().sort((carA, carB) => {
            // For numeric values
            if (isNumeric) {
                return direction === 'ascending' ? carA[key] - carB[key] : carB[key] - carA[key];
            }
            // For string values
            return direction === 'ascending'
                ? (carA[key] || '').toString().localeCompare((carB[key] || '').toString())
                : (carB[key] || '').toString().localeCompare((carA[key] || '').toString());
        });
        setData(sortedData);
        setSortConfig({key, direction});
    };
    const handleAddCarClick = () => {
        setShowForm(true);
        setIsModalAddOpen(true)
        setformDiv(makeForm)
    };
    const handleCloseAddCarClick = () => {
        setShowForm(false);
    };


    function sendQrLink(data){
        const whatsAppURL = `https://wa.me/+972${data.ownerPhone}?text=${encodeURIComponent(`https://oto.lega6.com/QrCar?id=${data.ID}`)} הכנסו ללינק הבא לצפייה בקוד: `;
        window.open(whatsAppURL, '_blank');
    }


    useEffect(() => {

    }, [chx]);
    function fillEditForm(data) {
        if (data.status == '3') {
            setChx(true)
        }
        else setChx(false)
        setEditFormData(data)
    }

    useEffect(() => {
        if(editFormData){
            setMessage(<>
                <div dir={'rtl'}>
                    <button
                        onClick={() => changeCarStt(editFormData.ID, editFormData.status)}>{editFormData.status == '2' ? 'אישור קבוע' : 'חסום רכב'}</button>
                    {cookiesmanage.getCookieVal('viewAuth') < 3 && <button onClick={() =>sendQrLink(editFormData)}>שלח קישור לווטסאפ</button>}
                    <form className={'save-car-wrapper'} data-item-id={editFormData.ID}>
                        <input type="text" id="EditcarID" placeholder="מספר רכב" defaultValue={editFormData.carID}/>
                        <input type="text" id="EditownerFirstName" placeholder="שם פרטי"
                               defaultValue={editFormData.ownerFirstName}/>
                        <input type="text" id="EditownerLastName" placeholder="שם משפחה" defaultValue={editFormData.ownerLastName}/>
                        <input type="number" id="EditownerID" placeholder="מא או תז" defaultValue={editFormData.ownerID}/>
                        <input type="number" id="EditownerPhone" placeholder="טלפון" defaultValue={editFormData.ownerPhone}/>
                        <Dropdown title={'סוג שירות'} opts={perGroup} type={3} idName='editPersonalId'
                                  pickedVal={editFormData.personGroup}/>
                        <input type="text" id="EditcarManufacturer" placeholder="סוג רכב"
                               defaultValue={editFormData.carManufacturer}/>
                        <input type="text" id="EditcarColor" placeholder="צבע" defaultValue={editFormData.carColor}/>
                        <Dropdown title={'סוג לוחית רישוי'}
                                  opts={[{text: 'אזרחית', value: 1}, {text: 'צבאית', value: 2},{text: 'משטרתית', value: 3}]}
                                  idName='EditisMilitary' pickedVal={editFormData.plateType}/>
                        <Dropdown opts={deptData} idName='EditDept' type={1} pickedVal={editFormData.departmentID}/>
                        <DatePickersValid givenID='editCarChx' onCheckboxChange={setChx} isCheck={chx} sTid='editCarStartT'
                                          sTVal={editFormData.startDate} eTVal={editFormData.endDate} eTid='editCarendT'/>
                    </form>
                </div>
            </>)
        }
    }, [chx, editFormData]);
    function changeCarStt(id, type) {
        const changedCar = async () => {
            try {
                let dataBuild = {
                    ID: id,
                    status: type !== '2' ? '2' : '1'
                }
                const changeCarStat = await carmanage.changeStatCar(dataBuild)
                setIsLoading(true);
                setIsModalOpen(false)
                if (changeCarStat) {
                    fetchCars()
                }
            } catch
                (error) {
                console.error('Error fetching cars:', error);
            }
        }
        changedCar()
    }


    function editCar(item) {
        const findCar = async () => {
            try {
                const detailsOfCar = await carmanage.getDetailedCar(item['מספר רכב'])
                setIsModalOpen(false)
                if (detailsOfCar) {
                    setIsModalOpen(true)
                    setMessage(<>{fillEditForm(detailsOfCar)}</>)
                    setApproveFunc(() => saveEditedCar)
                }
            } catch (error) {
                console.log(error)
            }
        }
        findCar()
    }

    function saveEditedCar() {
        const saveCar = async () => {
            try {
                let timeStart = 0
                let timeEnd = 0
                if (document.getElementById('editCarChx').checked) {
                    timeStart = document.getElementById('editCarStartT').value
                    timeEnd = document.getElementById('editCarendT').value
                }
                let dataBuild = {
                    ownerFirstName: document.getElementById('EditownerFirstName').value,
                    ID: document.querySelector('.save-car-wrapper').getAttribute('data-item-id'),
                    ownerLastName: document.getElementById('EditownerLastName').value,
                    status: document.getElementById('editCarChx').checked ? 3 : 1,
                    startDate: timeStart ? helpers.convertToUnixMilliseconds(timeStart) : 0,
                    endDate: timeEnd ? helpers.convertToUnixMilliseconds(timeEnd) : 0,
                    carID: document.getElementById('EditcarID').value,
                    personGroup: document.getElementById('editPersonalId').value,
                    ownerID: document.getElementById('EditownerID').value,
                    departmentID: document.getElementById('EditDept').value,
                    ownerPhone: document.getElementById('EditownerPhone').value,
                    plateType: document.getElementById('EditisMilitary').value,
                    carManufacturer: document.getElementById('EditcarManufacturer').value,
                    carColor: document.getElementById('EditcarColor').value,
                }
                const savedCar = await carmanage.editCar(dataBuild)
                setIsLoading(true);
                setIsModalOpen(false)
                if (savedCar) {
                    fetchCars()
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        }
        saveCar()
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (logs.isLog()) console.log('Form Submitted');
        const addCar = async () => {
            try {
                let timeStart = 0
                let timeEnd = 0
                if (document.getElementById('addCarChx').checked) {
                    timeStart = document.getElementById('addCarStartT').value
                    timeEnd = document.getElementById('addCarendT').value
                }
                let dataBuild = {
                    ownerFirstName: document.getElementById('ownerFirstName').value,
                    ownerLastName: document.getElementById('ownerLastName').value,
                    carID: document.getElementById('carID').value,
                    status: document.getElementById('addCarChx').checked ? '3' : '1',
                    startDate: timeStart ? helpers.convertToUnixMilliseconds(timeStart) : 0,
                    endDate: timeEnd ? helpers.convertToUnixMilliseconds(timeEnd) : 0,
                    ownerID: document.getElementById('ownerID').value,
                    departmentID: document.getElementById('dept').value,
                    personGroup: document.getElementById('addPersonalId').value,
                    ownerPhone: document.getElementById('ownerPhone').value,
                    plateType: document.getElementById('isMilitary').value,
                    carManufacturer: document.getElementById('carManufacturer').value,
                    carColor: document.getElementById('carColor').value,
                }
                const addedCar = await carmanage.addCar(dataBuild)
                setIsLoading(true);
                if (addedCar) {
                    setIsModalOpen(false)
                    setShowForm(false)
                    fetchCars()
                }
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        }
        addCar()
        // Submit the form data
    };
    const fetchCars = async () => {
        try {
            const carsData = await carmanage.getAllCarsList();
            if (carsData) {
                const carsArray = Array.isArray(carsData) ? carsData : [carsData];
                const carsWithDepartmentNames = await departmanage.getDeptNameFromArray(carsArray);
                const transformedData = helpers.transformKeys(carmanage.fixCarTable(carsWithDepartmentNames));
                setData(transformedData);
            }
        } catch (error) {
            console.error('Error fetching cars:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    function openHistory(item) {
        if (logs.isLog()) console.log('היסטוריה', item)
        setApproveFunc('')
        setIsModalOpen(true)
        fetchHistory(item['מספר רכב'])
    }

    const fetchHistory = async (carId) => {
        try {
            const carHistoryData = await carmanage.getCarHistory({carID: carId});
            if (carHistoryData && carHistoryData.length > 0) {
                setMessage(
                    <>
                        <ul style={{listStyleType: 'none', padding: 0}}>
                            {carHistoryData.map(ticket => (
                                <li key={ticket.ID} style={{
                                    backgroundColor: '#f0f0f0',
                                    margin: '5px 0',
                                    padding: '10px',
                                    borderRadius: '5px'
                                }}>
                                    {ticket.approverFirstName} {ticket.approverLastName}, {ticket.typeName}, {ticket.time}
                                </li>
                            ))}
                        </ul>
                    </>
                )
            } else {
                setMessage(<>אין היסטוריה</>)
            }
        } catch (error) {
            console.error('Error fetching cars:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        fetchCars();
        getOptsForDept();
        getPersonalGroups();
    }, []);
    const getPersonalGroups = async () => {
        try {
            const pGroupData = await persongroupmanage.getAllPersonalGroups();
            if (pGroupData) {
                setPerGroupsData(pGroupData)
            } else setPerGroupsData([]);
        } catch
            (error) {
            console.error('Error fetching cars:', error);
        }
    }
    const getOptsForDept = async () => {
        try {
            const deptData = await departmanage.getAllDeptsList();
            if (deptData) {
                setDeptData(deptData);
            }else setDeptData([])
        } catch (error) {
            console.error('Error fetching cars:', error);
        }
    }

    function makeForm() {
        return (
            <form className={'add-car-wrapper'} onSubmit={handleSubmit}>
                <input type="text" id="carID" placeholder="מספר רכב"/>
                <input type="text" id="ownerFirstName" placeholder="שם פרטי"/>
                <input type="text" id="ownerLastName" placeholder="שם משפחה"/>
                <input type="number" id="ownerID" placeholder="מא או תז"/>
                <input type="number" id="ownerPhone" placeholder="טלפון"/>
                <Dropdown title={'סוג שירות:'} opts={perGroup} type={3} idName='addPersonalId'/>
                <input type="text" id="carManufacturer" placeholder="חברה"/>
                <input type="text" id="carColor" placeholder="צבע"/>
                <Dropdown title={'סוג לוחית רישוי'}
                          opts={[{text: 'אזרחית', value: 1}, {text: 'צבאית', value: 2},{text: 'משטרתית', value: 3}]}
                          idName='isMilitary'/>
                <Dropdown title={'שיוך'} opts={deptData} idName='dept' type={1}/>
                <DatePickersValid isCheck={false} givenID='addCarChx' sTid='addCarStartT'
                                  eTid='addCarendT'/>
                <button type="submit">שמור</button>
                <button onClick={handleCloseAddCarClick}>ביטול</button>
            </form>)

    }

    return (
        <div>
            <div className={'carlist-header'}>
                <img className={"addcar-btn"} onClick={handleAddCarClick} src={addcarbtn} alt={''}/>
                {showForm && (
                    <Modal isOpen={isModalAddOpen} onClose={closeModalAdd}>
                        {formDiv}
                    </Modal>
                )}
                    <input
                        type="text"
                        className={'search-car-input'}
                        placeholder="חפש..." // Placeholder in Hebrew
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                    <Modal isOpen={isModalOpen} onClose={closeModal}>
                        <ConfirmBox message={isMessage} onClose={closeModal} onConfirm={approveFunc}/>
                    </Modal>
                <div className={"car-list-wrapper car-list"}>
                    {isLoading ? (
                        <Loading/> // Render the Loading component when data is loading
                    ) : (
                        <>
                            <DataList title={'רשימת רכבים'} data={currentItems} sortAction={handleSort}
                                      actions={actionBtns}/>
                        </>
                    )}
                </div>
                {!isLoading && <Pagination itemsPerPage={itemsPerPage} totalItems={data.length} paginate={paginate}
                                           currentPage={currentPage}/>}
        </div>
    );
}

export default Cars;