import React, { useEffect, useState } from 'react';

const AddToHomeScreen = () => {
    const [installPrompt, setInstallPrompt] = useState(null);

    const beforeInstallPromptHandler = (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault()
        console.log('Event received:', e);
        // Save the event, so it can be triggered later
        setInstallPrompt(e);
    };
    useEffect(() => {
        console.log('???TEST')

        window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

        return () => {
            window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
        };
    }, []);

    const installinApp = () => {
        console.log(installPrompt)
        if (installPrompt) {
            // Show the install prompt
            console.log("Showing prompt");
            installPrompt.prompt();
            // Wait for the user to respond to the prompt
            installPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setInstallPrompt(null);
            });
        }
    };

    return (
        <button onClick={installinApp} >
            הוסף לעמוד הבית
        </button>
    );
};

export default AddToHomeScreen;
