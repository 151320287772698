import datamanage from "./datamanage";
import helpers from "./helpers";

export default {
    getDetailedCar,
    getAllCarsList,
    addCar,
    changeStatCar,
    fixCarTable,
    getCarHistory,
    editCar
};

async function getCarHistory(carInfo) {
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': carInfo, 'type': {'act': 'carHistory'}}
        return await datamanage.sendData(data, 'sendback')
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}

function _changeDateCarsEnt(array) {
    return array.map(item => {
        if (item.status !== 2) {
            return {
                ...item,
                startDate: helpers.formatDate(item.startDate),
                endDate: helpers.formatDate(item.endDate)
            };
        } else {
            return {
                ...item,
                startDate: 'חסום',
                endDate: 'חסום'
            }
        }
    });
}

function _changeStatusCarView(array) {
    return array.map(item => {
        return {
            ...item,
            status: item.status == 1 ? 'מאושר' : item.status == 2 ? "חסום" : 'זמני'
        }
    })
}

function fixCarTable(array) {
    let newArray = _changeDateCarsEnt(array)
    newArray = _changeStatusCarView(newArray)
    return newArray
}


async function changeStatCar(carInfo) {
    try {
        console.log('HERE:', carInfo)
        let data = {'authentication': {"key": helpers.getToken()}, 'data': carInfo, 'type': {'act': 'changeCarStatus'}}
        return await datamanage.sendData(data, 'sendback')
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}

async function editCar(carInfo) {
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': carInfo, 'type': {'act': 'editCar','returnInput': '1'}}
        return await datamanage.sendData(data, 'sendback')
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}


async function addCar(carInfo) {
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': carInfo, 'type': {'act': 'addCar'}}
        return await datamanage.sendData(data, 'sendback')
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}

async function getAllCarsList() {
    let isValid = _isCarListSaved()
    if (!isValid) {
        try {
            let data = {'authentication': {"key": helpers.getToken()}, 'data': {}, 'type': {'act': 'carsList'}}
            let response = await datamanage.sendData(data, 'sendback')
            if (response) {
                helpers.saveSession('car-list', response)
                return response
            } else return null
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    } else {
        return isValid
    }
}

function _isCarListSaved() {
    let isCarList = helpers.isSessionValid('car-list')
    if (isCarList) return isCarList
    else return false
}


async function getDetailedCar(id, type = null) {
    let carList = helpers.isSessionValid('car-list');
    if (!carList) {
        carList = await getAllCarsList();
        if (!carList) {
            console.error('Failed to fetch car list');
            return null;
        }
        helpers.saveSession('car-list', carList);
    }
    let detailedCar = null;
    if (Array.isArray(carList)) {
        // carList is an array, use find
        if (type === null) {
            detailedCar = carList.find(car => car.carID === id);
        } else {
            detailedCar = carList.find(car => car.ID === id);
        }
    } else {
        // carList is a single object, check directly
        if ((type === null && carList.carID === id) || (type !== null && carList.ID === id)) {
            detailedCar = carList;
        }
    }
    return detailedCar;
}


