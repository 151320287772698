import datamanage from "./datamanage";
import helpers from "./helpers";

async function getAllGroups() {
    let isValid = _isGroupListSaved()
    if (!isValid) {
        try {
            let data = {'authentication': {"key": helpers.getToken()}, 'data': {}, 'type': {'act': 'groupsList'}}
            let response = await datamanage.sendData(data, 'sendback')
            helpers.saveSession('group-list', response)
            return response
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    } else {
        return isValid
    }
}

function _isGroupListSaved(){
    let isGroupList = helpers.isSessionValid('car-list')
    if(isGroupList) return isGroupList
    else return false
}



function delGroupById(id) {
    //return dataArray.filter(item => parseInt(item[0].value) !== parseInt(id));
    return ''
}

function editGroup(id, name) {
    // return dataArray.map(group => {
    //     if (parseInt(group[0].value) === parseInt(id)) {
    //         // Create a copy of the group array
    //         let updatedGroup = [...group];
    //         // Update the specific object (assuming it's at index 1 for 'שם קבוצה')
    //         updatedGroup[1].value = name;
    //         return updatedGroup;
    //     }
    //     return group;
    // });
}


async function getGroupNameFromArray(array){
    let isGroupList = helpers.isSessionValid('group-list')
    if(isGroupList){

    }else {
        let groupList= await getAllGroups();
        if(groupList){
            return _addGroupsNamesToUsers(array,groupList)
        }
    }
}
function _addGroupsNamesToUsers(arrayData, groups) {
    return arrayData.map(item => {
        const group = groups.find(group => group.ID == item.groupID);
        return {
            ...item,
            groupID: group ? group.groupName : 'שגיאה'
        };
    });
}

export default {
    getGroupNameFromArray,
    editGroup,
    getAllGroups,
    delGroupById
}