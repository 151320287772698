
import React, { useState, useEffect } from 'react';
import Loading from "./comps/Loading";
import carmanage from '../services/carmanage.js'
import logs from "../services/logs";
import helpers from "../services/helpers";
import departmanage from "../services/departmanage";

function DetailsPage({ id }) {
    const [itemData, setItemData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        setIsLoading(true);
        carmanage.getDetailedCar(id)
            .then(async response => {
                if (response) {
                    const augmentedResponse = await departmanage.getDeptNameFromArray([response]);
                    setItemData(augmentedResponse[0]);
                    if (logs.isLog()) console.log('this car:', response);
                    setError(null)
                } else if(!itemData){
                    setError('רכב לא נמצא');
                }else setError('רכב לא קיים כבר')
            })
            .catch(err => {
                setError(err.message || 'Error fetching car details');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [id]);


    function statusToShow(car){
        if (parseInt(car.status) == 1) return 'מאושר כניסה'
        else if (parseInt(car.status) == 2) return 'לא מאושר'
        if(helpers.isTodayBetween(car.startDate,car.endDate)) return ` מאושר זמני עד ${helpers.formatDate(car.endDate)}`
        else return 'לא מאושר'
    }


    // Fetch more details based on the ID or perform other logic
    if (isLoading) return <Loading/>;
    if (error) return <div>שגיאה: {error}</div>;
    return (
        <div>
            {/* Render more detailed information here */}
            <div>
                {itemData && (
                    <>
                        <h1>מספר רכב: {itemData.plateType == '1' ? '' : itemData.plateType == '2' ? 'צ-' : 'מ-'} {itemData.carID}</h1>
                        <div dir={'rtl'} className={'car-details'} style={{ background: statusToShow(itemData) != 'לא מאושר' ? '#4fd54f' : 'red' }}>
                            {/* Card 1: Approval Status */}
                            <h2>{statusToShow(itemData)}</h2>
                            <div>
                                {itemData.carID > 0 &&
                                    <div>
                                        <div><span>מבצע: </span><span>{itemData.approverFirstName + ' ' + itemData.approverLastName}</span></div>
                                        <div><span>תאריך עדכון אחרון: </span><span>{helpers.formatDate(itemData.lastUpdateTime)}</span></div>
                                        {/*<div><span>בעל מדבקה: </span><span>{itemData[1].value > 0 ? '118' : 'לא '}</span></div>*/}
                                        {/*<div><span>תאריך התחלה: </span><span>{itemData[3].value}</span></div>*/}
                                        {/*<div><span>תאריך סיום: </span><span>{itemData[4].value}</span></div>*/}
                                    </div>}
                            </div>
                        </div>
                        <div>
                            <div className={'bla car-detail-2'}>
                                <h4>פרטי רכב</h4>
                                <div>
                                    <div><span>חברת רכב: </span><span>{itemData.carManufacturer}</span></div>
                                    <div><span>סוג לוחית רישוי: </span><span>{itemData.name}</span></div>
                                    <div><span>צבע: </span><span>{itemData.carColor}</span></div>
                                    <div><span>מספר רכב: </span><span>{itemData.carID}</span></div>
                                    {/*<div><span>נחסם בעבר: </span><span>לא</span></div>*/}
                                </div>
                            </div>

                            <div className={'car-details-1'}>
                                <h4>פרטי בעל רכב</h4>
                                <div>
                                    <div><span>שם פרטי: </span><span>{itemData.ownerFirstName}</span></div>
                                    <div><span>שם משפחה: </span><span>{itemData.ownerLastName}</span></div>
                                    <div><span>טלפון: </span><span>{itemData.ownerPhone}</span></div>
                                    <div><span>משויך ל: </span><span>{itemData.departmentID}</span></div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default DetailsPage;
