import helpers from "./helpers";
import datamanage from "./datamanage";


async function getAllEnteryReq() {
    let isValid = _isReqListSaved()
    if (!isValid) {
        try {
            let data = {'authentication': {"key": helpers.getToken()}, 'data': {}, 'type': {'act': 'requestsList'}}
            let response = await datamanage.sendData(data, 'sendback')
            helpers.saveSession('req-list', response)
            return response
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    } else {
        return isValid
    }
}

function _isReqListSaved() {
    let isReqList = helpers.isSessionValid('req-list')
    if (isReqList) return isReqList
    else return false
}


async function requestDecision(reqData, type = null) {
    try {
        let data = {'authentication': {"key": helpers.getToken()}, 'data': reqData, 'type': {'act': 'requestDecision'}}
        if (type != null) data.type.rewriteDuplicate = '1'
        return await datamanage.sendData(data, 'sendback')
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}

async function getDetailedRequest(id, type = null) {
    let reqList = helpers.isSessionValid('car-list');
    if (!reqList) {
        reqList = await getAllEnteryReq();
        if (!reqList) {
            console.error('Failed to fetch car list');
            return null;
        }
        helpers.saveSession('req-list', reqList);
    }

    // Handle both array and single object scenarios
    let detailedTicket;
    if (!Array.isArray(reqList)) {
        // If reqList is a single object, directly compare its ID
        if (type === null) {
            detailedTicket = reqList.carID === id ? reqList : null;
        } else {
            detailedTicket = reqList.ID === id ? reqList : null;
        }
    } else {
        // If reqList is an array, use the find method
        if (type === null) {
            detailedTicket = reqList.find(car => car.carID === id);
        } else {
            detailedTicket = reqList.find(car => car.ID === id);
        }
    }

    return detailedTicket || null;
}


function fixReqTable(arr) {
    let newArr = _fixLastUser(arr)
    newArr = _fixIsMil(newArr)
    return newArr
}

function _fixIsMil(array) {
    return array.map(item => {
        return {
            ...item,
            requestStatus: item.requestStatus == 0 ? 'לא טופל' : item.requestStatus == 1 ? 'מאושר' : item.requestStatus == 2 ? 'נדחה' : 'אושר זמני'
        }
    })
}

function _fixLastUser(arr) {
    return arr.map(item => {
        return {
            ...item,
            userID: item.userID == 0 ? 'אורח' : 'משתמש'
        }
    })
}

export default {
    getAllEnteryReq,
    getDetailedRequest,
    fixReqTable,
    requestDecision
}