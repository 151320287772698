import axios from "axios";
import logs from "./logs";
import cookiesmanage from "./cookiesmanage";

const dataUrl = 'https://oto.lega6.com/php/index.php'

function sendData(data, func) {
    if(logs.isLog()) console.log(data.type,func)
    return axios.post(dataUrl, data)
        .then(response => {
            if(logs.isLog()) console.log('res:',response)
            if (func !== 'reset' && response.data.statusCode !== 100 && response.data.debug.statusCode !== 100) {
                if(logs.isLog()) console.log('error msg:', response.data.debug,'code:',response.data.debug.statusCode);
                switch (response.data.debug.statusCode){
                    case 313:
                        func({type:'error',text:'פרטים שגויים'})
                        break;
                    case 312:
                        func({type:'oldPass',text:'פרטים ישנים- לבצע איפוס סיסמא'})
                        break;
                    case 103:
                        return response
                    case 105:
                        return response
                    case 106:
                        return response
                    case 402:
                        return {id:response.data.debug.duplicateID,problem: 'dupl',area: data.type.act}
                    case 403:
                        return 'dupl'
                    case 303:
                        window.location.href = '/'
                        return {problem: 'token end'}
                    case 304:
                        cookiesmanage.removeAllCookies()
                        window.location.href = '/'
                        return {problem: 'token end'}
                    case 102:
                        return null;
                    default:
                        func()
                        break;
                }
                // Reject the promise in case of an error
            } else {
                if (logs.isLog()) console.log('data in return:', response.data.data);
                if (func !== 'sendback' && func !== 'reset') {
                    if(response.data.data ) func(response.data.data)
                    // If no data needs to be returned
                } else if (func === 'sendback') {
                    // Resolve the promise with data
                    if (logs.isLog())  console.log(func,response.data)
                    if(response.data.data ) return response.data.data
                    else return true
                }else if(func === 'reset'){
                    console.log(response.data)
                    cookiesmanage.removeAllCookies()
                    //window.location.reload()
                }
            }
        })
        .catch(error => {
            if (logs.isLog()) console.error('Error:', error);
            return Promise.reject(error);
        });
}


export default {
    sendData
}