import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from "react-datepicker";
import he from 'date-fns/locale/he';


registerLocale('he', he);

function ConditionalDateTimePicker(props) {
    const [showPicker, setShowPicker] =  useState(false);
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    // Synchronize showPicker with isCheck prop
    useEffect(() => {
        setShowPicker(props.isCheck);
    }, [props.isCheck]);

    const handleCheckboxChange = () => {
        setShowPicker(!showPicker);
        // If you need to lift the state up or perform an action on change, do it here
        if (props.onCheckboxChange) {
            props.onCheckboxChange(!showPicker);
        }
    };

    useEffect(() => {
        if(props.eTVal && props.eTVal !== '0'){
            setEndDate(new Date(parseInt(props.eTVal)))
            setStartDate(new Date(parseInt(props.sTVal)))
        }
    }, [props.eTVal, props.sTVal]);

    return (
        <div className={"type-enter"}>
            <div>
                <input
                    id={props.givenID}
                    type="checkbox"
                    checked={showPicker}
                    onChange={handleCheckboxChange}
                />
               כניסה מתוזמנת
            </div>

            {showPicker && (
                <>
                    <label>התחלה:</label>
                    <DatePicker
                        id={props.sTid}
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="שעה"
                        locale='he'
                    />
                    <label>סיום:</label>
                    <DatePicker
                        id={props.eTid}
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="שעה"
                        locale='he'
                    />
                </>
            )}
        </div>
    );
}

export default ConditionalDateTimePicker;
